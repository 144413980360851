import {
  ArrowForward,
  Clear,
  PhotoCameraRounded,
  PhotoSharp,
  Upload,
} from '@mui/icons-material'
import {Alert, Avatar, Box, Button, Stack} from '@mui/material'
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'
import {usePfpProfileUpload, useProfile} from 'hooks'
import {useEffect, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {useUser} from 'reactfire'

interface PfpStepProps {
  onSuccess: () => void
  onBack: () => void
}

type FormValues = {
  photoURL?: FileList | null
}

export function PfpStep({onSuccess, onBack}: PfpStepProps) {
  const {docId} = useParams()
  const {data: profile} = useProfile(docId ? docId : null)
  const {data: user} = useUser()
  const storage = getStorage()
  const {updateProfileWithExistingUrl} = usePfpProfileUpload(docId ? docId : '')

  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const {
    handleSubmit,
    watch,
    setValue,
    formState: {errors, isValid},
    register,
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {photoURL: null},
  })

  const photoURL = watch('photoURL')

  useEffect(() => {
    if (profile?.pfp) {
      setImagePreview(profile.pfp)
    } else if (user?.photoURL) {
      setImagePreview(user.photoURL)
    }
  }, [profile?.pfp, user?.photoURL])

  useEffect(() => {
    if (photoURL && photoURL.length > 0) {
      const file = photoURL[0]
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        setImagePreview(fileReader.result as string)
      }
      fileReader.readAsDataURL(file)
    }
  }, [photoURL])

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setValue('photoURL', files)
    }
  }

  const handleClear = () => {
    setValue('photoURL', null)
    setImagePreview(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    reset({photoURL: null})
  }

  const handleUpload = async (data: FormValues) => {
    if (!data.photoURL || !docId) return
    setIsUploading(true)

    try {
      const file = data.photoURL[0]
      const storageRef = ref(storage, `pfp/${docId}`)
      await uploadBytes(storageRef, file)
      const downloadURL = await getDownloadURL(storageRef)
      await updateProfileWithExistingUrl(docId, downloadURL)
      setImagePreview(downloadURL)
      onSuccess()
    } catch (error) {
      console.error('Error uploading image:', error)
    } finally {
      setIsUploading(false)
    }
  }

  const handleContinue = async () => {
    if (imagePreview && docId) {
      try {
        await updateProfileWithExistingUrl(docId, imagePreview)
        onSuccess()
      } catch (error) {
        console.error('Failed to update profile with existing URL:', error)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(handleUpload)}>
      <Stack spacing={2} alignItems="center">
        <Avatar
          src={imagePreview || undefined}
          sx={{width: 150, height: 150}}
        />
        <input
          type="file"
          accept="image/*"
          {...register('photoURL', {
            onChange: handleFileChange,
            validate: {
              fileSize: value =>
                !value ||
                value.length === 0 ||
                value[0].size <= 10000000 ||
                'File size should not exceed 10mb.',
              fileType: value =>
                !value ||
                value.length === 0 ||
                ['image/jpeg', 'image/png', 'image/gif'].includes(
                  value[0].type,
                ) ||
                'Invalid file type. Only JPG, PNG, and GIF are allowed.',
            },
          })}
          ref={fileInputRef}
          style={{display: 'none'}}
        />
        <Stack direction="column" spacing={2} width="100%">
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={
                !imagePreview ? <PhotoCameraRounded /> : <PhotoSharp />
              }
              onClick={handleFileInputClick}
            >
              {!imagePreview ? 'Select' : 'Replace'}
            </Button>
            <Button
              variant="outlined"
              startIcon={<Clear />}
              fullWidth
              onClick={handleClear}
              disabled={!imagePreview}
            >
              Clear
            </Button>
          </Stack>
          <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
            <Button
              color="inherit"
              disabled={true}
              onClick={onBack}
              sx={{mr: 1}}
            >
              Back
            </Button>
            <Box sx={{flex: '1 1 auto'}} />

            {photoURL && photoURL.length > 0 ? (
              <Button
                type="submit"
                variant="contained"
                color="success"
                startIcon={<Upload />}
                disabled={isUploading || !isValid}
              >
                {isUploading ? 'Uploading...' : 'Upload'}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForward />}
                onClick={handleContinue}
                disabled={!imagePreview}
              >
                Next
              </Button>
            )}
          </Box>
        </Stack>
        {errors.photoURL && (
          <Alert severity="error">{errors.photoURL.message}</Alert>
        )}
        {!imagePreview && (
          <Alert severity="info">
            Please select a square image under 2MB for best results.
          </Alert>
        )}
      </Stack>
    </form>
  )
}
