// DetailsStep.tsx
import {yupResolver} from '@hookform/resolvers/yup'
import {Box, Button, TextField, Typography} from '@mui/material'
import {Timestamp} from 'firebase/firestore'
import {useEffect} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import * as yup from 'yup'
import {useProfile, useUpdateProfileDetails} from '../../hooks/useProfile'

interface DetailsStepProps {
  profileId: string
  onSuccess: () => void
  onBack: () => void
}

interface FormData {
  displayName: string
  description?: string
}

const schema = yup.object().shape({
  displayName: yup.string().required('Display name is required'),
  description: yup
    .string()
    .max(280, 'Description must not exceed 280 characters')
    .optional(),
})

export function DetailsStep({profileId, onSuccess, onBack}: DetailsStepProps) {
  const {data: profile, isLoading} = useProfile(profileId)
  const updateProfile = useUpdateProfileDetails(profileId)

  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      displayName: '',
      description: '',
    },
  })

  const description = watch('description')

  const onSubmit: SubmitHandler<FormData> = data => {
    updateProfile.mutate(
      {...data, updatedAt: Timestamp.now()},
      {
        onSuccess: () => {
          onSuccess()
        },
      },
    )
  }

  useEffect(() => {
    if (profile) {
      reset({
        displayName: profile.displayName || '',
        description: profile.description || '',
      })
    }
  }, [profile])

  if (isLoading) return <Typography>Loading...</Typography>

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 2}}>
      <TextField
        {...register('displayName')}
        label="Display Name"
        fullWidth
        margin="normal"
        error={!!errors.displayName}
        helperText={errors.displayName?.message}
      />
      <TextField
        {...register('description')}
        label="Description (optional)"
        fullWidth
        multiline
        rows={4}
        margin="normal"
        error={!!errors.description}
        helperText={
          errors.description?.message ||
          `${280 - (description?.length || 0)} characters remaining`
        }
        slotProps={{input: {inputProps: {maxLength: 280}}}}
      />

      <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
        <Button color="inherit" disabled={true} onClick={onBack} sx={{mr: 1}}>
          Back
        </Button>
        <Box sx={{flex: '1 1 auto'}} />

        <Button type="submit" variant="contained" sx={{mt: 2}}>
          Next
        </Button>
      </Box>
    </Box>
  )
}
