import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import {JoinPancake, PublicProfileCard} from 'components'
import {usePublicProfiles} from 'hooks'
import {useNavigate} from 'react-router-dom'
import {useSigninCheck} from 'reactfire'

export function Home() {
  const navigate = useNavigate()
  const onClick = () => {
    navigate('/join')
  }

  const {data: member} = useSigninCheck({
    requiredClaims: {member: true},
  })

  const {data: profiles, isLoading} = usePublicProfiles()

  return (
    <Stack spacing={2}>
      <Stack textAlign={'center'} alignItems={'center'} spacing={0.5}>
        <Typography variant="h5">Pancake Bio</Typography>
        <Typography variant="body2" color="text.secondary" maxWidth={'sm'}>
          Pancake.bio is a directory of adult content creators and
          collaborators. Our mission is to provide a safe space for creators to
          share their work and connect with others in the industry.
        </Typography>
      </Stack>
      <Divider>
        {!member?.hasRequiredClaims && (
          <Button onClick={onClick}>Create your own Profile</Button>
        )}
      </Divider>
      <Stack spacing={2} textAlign={'center'}>
        <Typography variant="h6">Pancake Members</Typography>
        <Stack>
          {isLoading && !profiles && <CircularProgress />}
          <Stack
            direction="row"
            spacing={3}
            flexWrap={'wrap'}
            justifyContent={'center'}
            useFlexGap
          >
            {profiles?.map(profile => (
              <PublicProfileCard key={profile.slug} profile={profile} />
            ))}
          </Stack>
        </Stack>
      </Stack>

      {!member?.hasRequiredClaims && <JoinPancake />}
    </Stack>
  )
}
