import {useQuery} from '@tanstack/react-query'
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore'

import {ProfileData} from 'types'

const fetchActiveProfileBySlug = async (
  slug: string,
  firestore: any,
): Promise<ProfileData> => {
  const profilesRef = collection(firestore, 'profiles')
  const q = query(
    profilesRef,
    where('slug', '==', slug),
    where('active', '==', true),
  )

  const querySnapshot = await getDocs(q)

  if (querySnapshot.empty) {
    throw new Error('Active profile not found')
  }

  const profileDoc = querySnapshot.docs[0]
  const profileData = profileDoc.data() as ProfileData
  return {...profileData, docId: profileDoc.id}
}

export function useActiveProfileBySlug(slug: string | undefined) {
  const firestore = getFirestore()

  return useQuery<ProfileData>({
    queryKey: ['m', slug],
    queryFn: () => fetchActiveProfileBySlug(slug!, firestore),
    enabled: !!slug,
  })
}
