import {getAuth, RecaptchaVerifier} from 'firebase/auth'
import {useEffect} from 'react'

export function useRecaptcha() {
  const auth = getAuth()

  useEffect(() => {
    initializeRecaptcha()
  }, [auth])

  const initializeRecaptcha = () => {
    const recaptchaContainerId = 'recaptcha-container'

    // Remove and recreate the ReCAPTCHA container to avoid duplicates
    resetRecaptchaContainer(recaptchaContainerId)

    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      recaptchaContainerId,
      {
        size: 'invisible',
        callback: () => console.log('ReCAPTCHA Resolved'),
      },
    )
  }

  const resetRecaptchaContainer = (id: string) => {
    const recaptchaContainer = document.getElementById(id)
    if (recaptchaContainer) {
      recaptchaContainer.remove()
    }

    const newRecaptchaContainer = document.createElement('div')
    newRecaptchaContainer.id = id
    document.body.appendChild(newRecaptchaContainer)
  }

  // Function to reset and reinitialize the ReCAPTCHA
  const resetRecaptcha = () => {
    console.log('Resetting ReCAPTCHA...')
    initializeRecaptcha()
  }

  return {resetRecaptcha}
}
