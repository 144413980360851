import {Button} from '@mui/material'
import {drawerOpenState} from 'data'
import {useLocation, useNavigate} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'

export function DrawerButton({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) {
  const {pathname} = useLocation()
  const isActive = href === '/' ? pathname === href : pathname.startsWith(href)
  const toggleDrawer = useSetRecoilState(drawerOpenState)
  const navigate = useNavigate()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    navigate(href) // Navigate to the target href
    toggleDrawer(false) // Close the drawer
  }
  return (
    <Button
      fullWidth
      color={isActive ? 'primary' : 'inherit'}
      variant={isActive ? 'contained' : 'text'}
      onClick={handleClick}
    >
      {children}
    </Button>
  )
}
