import {
  DashboardRounded,
  ManageAccounts,
  PeopleRounded,
} from '@mui/icons-material'
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import {profileMenuState} from 'data'
import React, {useCallback} from 'react'
import {useNavigate} from 'react-router-dom'
import {useUser} from 'reactfire'
import {useRecoilState} from 'recoil'
import {ROUTES} from 'router'
import {LogoutButton} from './LogoutButton'

export function ProfileButton() {
  const {data: user} = useUser()
  const [anchorEl, setAnchorEl] = useRecoilState(profileMenuState)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!user) return navigate(ROUTES.LOGIN)
      setAnchorEl(event.currentTarget)
    },
    [user],
  )

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (path: string) => {
    handleClose()
    return navigate(path)
  }

  const handleLogin = () => {
    navigate(ROUTES.LOGIN)
  }

  return (
    <React.Fragment>
      <Tooltip title={user ? 'Manage Your Account' : 'Login'}>
        {user ? (
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge
              color="success"
              variant="dot"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Avatar
                sx={{width: 32, height: 32}}
                src={user?.photoURL ?? undefined}
              />
            </Badge>
          </IconButton>
        ) : (
          <IconButton size="small" sx={{ml: 2}} onClick={handleLogin}>
            <Avatar sx={{width: 32, height: 32}} />
          </IconButton>
        )}
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <Typography variant="caption" color="textSecondary" px={1}>
          {user?.email}
        </Typography>

        <MenuItem onClick={() => handleMenuItemClick(ROUTES.DASHBOARD)}>
          <ListItemIcon>
            <DashboardRounded />
          </ListItemIcon>
          Dashboard
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(ROUTES.PROFILES)}>
          <ListItemIcon>
            <PeopleRounded />
          </ListItemIcon>
          Profiles
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleMenuItemClick(ROUTES.ACCOUNT)}>
          <ListItemIcon>
            <ManageAccounts fontSize="small" />
          </ListItemIcon>
          Account
        </MenuItem>
        <LogoutButton />
      </Menu>
    </React.Fragment>
  )
}
