import {ApplicationWizard, RegistrationWizard} from 'forms'
import {useEffect} from 'react'
import {useUser} from 'reactfire'

export function Membership() {
  const {data: user} = useUser()
  const isRegistered = user ? user.phoneNumber : false

  useEffect(() => {
    if (!user) return
    ;(async () => {
      await user.getIdToken(true)
    })()
  }, [user])

  if (isRegistered) return <ApplicationWizard />
  return <RegistrationWizard />
}
