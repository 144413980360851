import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from '@mui/material'
import {profileIdState} from 'data'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import {ProfileDocType} from '../types' // Adjust import path as needed

interface ProfileCardProps {
  profile: ProfileDocType
  active?: boolean
}

export const ProfileCard: React.FC<ProfileCardProps> = ({profile, active}) => {
  const navigate = useNavigate()
  const setProfileId = useSetRecoilState(profileIdState)
  const handleClick = () => {
    if (active) return navigate(`/m/${profile.slug}`)
    setProfileId(profile.docId)
    navigate(`/profiles/${profile.slug}/${profile.docId}/edit`)
  }

  return (
    <Card
      sx={{
        cursor: 'pointer',
        transition: '0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: 3,
        },
      }}
      onClick={handleClick}
    >
      <CardMedia
        component="img"
        height="140"
        image={profile.pfp || '/default-profile-picture.jpg'} // Provide a default image path
        alt={profile.displayName || profile.slug}
      />
      <CardContent>
        <Typography variant="h6" component="div" noWrap>
          {profile.slug}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          {profile.displayName || 'No display name'}
        </Typography>
        <Box sx={{mt: 1}}>
          <Chip
            label={profile.active ? 'Live' : 'Draft'}
            color={profile.active ? 'success' : 'default'}
            size="small"
          />
        </Box>
      </CardContent>
    </Card>
  )
}
