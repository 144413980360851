import {CloseRounded, UploadFileRounded} from '@mui/icons-material'
import {Box, Button, IconButton, Paper, Stack, Typography} from '@mui/material'
import {useApplication} from 'hooks'
import React, {useEffect, useState} from 'react'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'

const MAX_FILE_SIZE_MB = 10
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024

interface FileInputWithPreviewProps<TFieldValues extends FieldValues> {
  onUpload: (files: FileList) => Promise<string[]>
  existingFiles?: string[] | null | undefined
  name: FieldPath<TFieldValues>
}

export function FileInputWithPreview<TFieldValues extends FieldValues>({
  onUpload,
  existingFiles = [],
  name,
}: FileInputWithPreviewProps<TFieldValues>) {
  const {
    control,
    formState: {errors},
    setValue,
    watch,
  } = useFormContext<TFieldValues>()

  const existingFieldName =
    `existing${name.charAt(0).toUpperCase() + name.slice(1)}` as FieldPath<TFieldValues>
  const existingFieldValue = watch(existingFieldName) as string[] | undefined
  const fieldValue = watch(name) as File[] | undefined

  const [files, setFiles] = useState<File[]>(fieldValue || [])
  const [previews, setPreviews] = useState<string[]>(
    existingFieldValue
      ? existingFieldValue
      : existingFiles
        ? existingFiles
        : [],
  )
  const [fileErrors, setFileErrors] = useState<string[]>([])

  const {data: application} = useApplication()

  useEffect(() => {
    setValue(name, files as any)
  }, [files, setValue, name])

  useEffect(() => {
    if (application && application[name as keyof typeof application]) {
      const appFiles = application[name as keyof typeof application] as string[]
      if (appFiles.length) {
        setPreviews(appFiles.filter(file => typeof file === 'string'))
      }
    }
  }, [application, name])

  const getFileTypeFromUrl = (url: string): string => {
    try {
      const extension = new URL(url).pathname.split('.').pop()?.toLowerCase()
      if (!extension) return 'unknown'
      if (['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp'].includes(extension))
        return 'image'
      if (['pdf'].includes(extension)) return 'pdf'
      return 'unknown'
    } catch (error) {
      console.error('Error parsing URL:', error)
      return 'unknown'
    }
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFiles = Array.from(event.target.files || [])
    const validFiles: File[] = []
    const errors: string[] = []

    selectedFiles.forEach(file => {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        errors.push(
          `File ${file.name} exceeds the ${MAX_FILE_SIZE_MB}MB size limit.`,
        )
      } else {
        validFiles.push(file)
      }
    })

    if (validFiles.length) {
      const newFiles = [...files, ...validFiles]
      setFiles(newFiles)

      const dataTransfer = new DataTransfer()
      validFiles.forEach(file => dataTransfer.items.add(file))
      const uploadedUrls = await onUpload(dataTransfer.files)

      const newPreviews = previews
        ? [...previews, ...uploadedUrls]
        : [...uploadedUrls]
      setPreviews(newPreviews)
      setValue(existingFieldName, newPreviews as any)
    }

    setFileErrors(errors)
    event.target.value = ''
  }

  const handleRemoveFile = (index: number) => {
    const newFiles = files.filter((_, i) => i !== index)
    const newPreviews = previews.filter((_, i) => i !== index)
    setFiles(newFiles)
    setPreviews(newPreviews)
    setValue(existingFieldName, newPreviews as any)
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onBlur, ref}}) => (
        <Stack spacing={2}>
          <Button
            variant="outlined"
            component="label"
            color="secondary"
            startIcon={<UploadFileRounded />}
            sx={{mb: 2, width: '200px'}}
          >
            Select File
            <input
              type="file"
              accept=".jpeg, .jpg, .png, .pdf"
              multiple
              onChange={handleFileChange}
              onBlur={onBlur}
              ref={ref}
              style={{display: 'none'}}
            />
          </Button>

          {fileErrors.map((error, index) => (
            <Typography key={index} variant="caption" color="error">
              {error}
            </Typography>
          ))}

          <Stack
            direction="row"
            spacing={2}
            rowGap={2}
            columnGap={2}
            useFlexGap
            flexWrap={'wrap'}
          >
            {previews.map((preview, index) => {
              const fileType = getFileTypeFromUrl(preview)
              return (
                <Paper
                  key={`existing-${index}`}
                  sx={{
                    position: 'relative',
                    width: 200,
                    height: 200,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                  }}
                >
                  {fileType === 'image' ? (
                    <img
                      src={preview}
                      alt={`Preview ${index}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        maxWidth: 200,
                        maxHeight: 200,
                        objectFit: 'cover',
                      }}
                    />
                  ) : fileType === 'pdf' ? (
                    <iframe
                      src={preview}
                      title={`PDF Preview ${index}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                      }}
                    />
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                      bgcolor="#f5f5f5"
                      border="1px solid #ddd"
                    >
                      <Typography variant="body2">
                        Unsupported Document
                      </Typography>
                    </Box>
                  )}
                  <IconButton
                    onClick={() => handleRemoveFile(index)}
                    color="error"
                    sx={{
                      position: 'absolute',
                      top: -10,
                      right: -10,
                      backgroundColor: 'white',
                    }}
                  >
                    <CloseRounded />
                  </IconButton>
                </Paper>
              )
            })}

            {files.map((file, index) => (
              <Paper
                key={`new-${index}`}
                sx={{
                  position: 'relative',
                  width: 200,
                  height: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                }}
              >
                {file.type.includes('image') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      maxWidth: 200,
                      maxHeight: 200,
                      objectFit: 'cover',
                    }}
                  />
                ) : file.type === 'application/pdf' ? (
                  <iframe
                    src={URL.createObjectURL(file)}
                    title={`PDF Preview ${index}`}
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                  />
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    bgcolor="#f5f5f5"
                    border="1px solid #ddd"
                  >
                    <Typography variant="body2">
                      Unsupported Document
                    </Typography>
                  </Box>
                )}
                <IconButton
                  onClick={() => handleRemoveFile(index)}
                  color="error"
                  sx={{
                    position: 'absolute',
                    top: -10,
                    right: -10,
                    backgroundColor: 'white',
                  }}
                >
                  <CloseRounded />
                </IconButton>
              </Paper>
            ))}
          </Stack>

          {errors[name] && (
            <Typography variant="caption" color="error">
              {(errors[name] as any)?.message}
            </Typography>
          )}
        </Stack>
      )}
    />
  )
}
