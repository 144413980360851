import {Box, Typography} from '@mui/material'
import {useApplication} from 'hooks'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {AccountStep} from './Account'
import {ApplicantOnly} from './Applied'
import {DetailsStep} from './Details'
import {IDsStep} from './IDs'
import {PaymentsStep} from './Payments'
import {ReviewStep} from './Review'
import {SocialStep} from './Socials'
import {ResponsiveStepper} from './Stepper'

export const steps = [
  'account',
  'socials',
  'details',
  'ids',
  'payments',
  'finish',
]

export function ApplicationWizard() {
  const {data: application} = useApplication() // Assuming you have a similar hook for applications
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const mode = searchParams.get('mode')
    const step = searchParams.get('step')
    if (mode !== 'apply' && step !== null && !steps.includes(step)) {
      setSearchParams({mode: 'apply', step: 'account'})
    }
  }, [searchParams, setSearchParams])

  if (application && application.status === 'submitted')
    return <ApplicantOnly />

  const renderStepContent = (step: string) => {
    switch (step) {
      case 'account':
        return <AccountStep />
      case 'socials':
        return <SocialStep />
      case 'details':
        return <DetailsStep />
      case 'ids':
        return <IDsStep />
      case 'payments':
        return <PaymentsStep />
      case 'finish':
        return <ReviewStep />
      default:
        return <Typography>Invalid step</Typography>
    }
  }

  const currentStep = searchParams.get('step') || 'account'
  const activeStep = steps.indexOf(currentStep)

  const handleStepClick = (index: number) => {
    setSearchParams({mode: 'apply', step: steps[index].toString()})
  }

  return (
    <Box sx={{width: '100%'}}>
      <Typography variant="h5" gutterBottom>
        Application
      </Typography>

      <ResponsiveStepper
        steps={steps}
        activeStep={activeStep}
        handleStepClick={handleStepClick}
      />

      <Box sx={{mt: 2}}>{renderStepContent(currentStep)}</Box>
    </Box>
  )
}
