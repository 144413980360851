import {Container, Stack} from '@mui/material'
import {Footer, Logo, Navigation} from 'components'
import {useScrollToTop} from 'hooks'
import {Outlet} from 'react-router-dom'

export function WrapperLayout() {
  useScrollToTop()
  return (
    <Container maxWidth="md" sx={{pt: 1, pb: 8}}>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={2}
      >
        <Logo />
        <Navigation />
      </Stack>
      <Outlet />

      <Footer />
    </Container>
  )
}
