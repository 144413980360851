import {
  DeleteForeverRounded,
  EditRounded,
  VisibilityOffRounded,
} from '@mui/icons-material'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {QueryObserverResult, RefetchOptions} from '@tanstack/react-query'
import {Timestamp} from 'firebase/firestore'
import {useDeleteProfile, useUpdateProfileDetails} from 'hooks'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ProfileData} from 'types'

export function MemberControls({
  displayName,
  slug,
  docId,
  refetch,
}: {
  displayName?: string
  slug?: string
  docId?: string
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<ProfileData, Error>>
}) {
  const navigate = useNavigate()
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState('')
  const update = useUpdateProfileDetails(docId ?? '')
  const deleteProfile = useDeleteProfile(docId ?? '')

  const handleDeactivate = async () => {
    update.mutate({active: false, updatedAt: Timestamp.now()})
    await refetch()
    // Deactivation logic will be added here
    setDeactivateModalOpen(false)
    navigate('/profiles') // Adjust this path as needed
  }

  const handleDelete = async () => {
    try {
      await deleteProfile.mutateAsync()
      setDeleteModalOpen(false)
      // Navigate to a different page after successful deletion
      navigate('/profiles') // Adjust this path as needed
    } catch (error) {
      console.error('Error deleting profile:', error)
      // You might want to show an error message to the user here
    }
  }

  return (
    <Stack textAlign={'center'} spacing={1}>
      <Divider>
        <Typography variant="overline" color="text.secondary">
          Hello {displayName}, Manage your profile
        </Typography>
      </Divider>
      <Stack direction="row" spacing={2} justifyContent={'center'}>
        <Button
          startIcon={<EditRounded />}
          color="inherit"
          onClick={() =>
            navigate(`/profiles/${slug}/${docId}/edit?mode=edit&step=details`)
          }
        >
          Edit
        </Button>
        <Button
          startIcon={<VisibilityOffRounded />}
          color="inherit"
          onClick={() => setDeactivateModalOpen(true)}
        >
          Deactivate
        </Button>
        <Button
          startIcon={<DeleteForeverRounded />}
          color="error"
          onClick={() => setDeleteModalOpen(true)}
        >
          Delete
        </Button>
      </Stack>

      {/* Deactivate Confirmation Modal */}
      <Dialog
        open={deactivateModalOpen}
        onClose={() => setDeactivateModalOpen(false)}
        PaperProps={{elevation: 0}}
      >
        <DialogTitle>Confirm Deactivation</DialogTitle>
        <DialogContent>
          <Typography>
            Deactiving your profile will hide it from public view. You can
            always reactivate it later.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeactivateModalOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleDeactivate} color="error" variant="contained">
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        PaperProps={{elevation: 0}}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography mb={2}>
            Are you sure you want to delete your profile? This action cannot be
            undone.
          </Typography>
          <Alert severity="error">
            Please type "<b>{slug}</b>" to confirm deletion:
          </Alert>
          <TextField
            fullWidth
            value={deleteConfirmation}
            onChange={e => setDeleteConfirmation(e.target.value)}
            margin="normal"
            label="Profile Name"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="error"
            disabled={deleteConfirmation !== slug || deleteProfile.isPending}
            variant="contained"
          >
            {deleteProfile.isPending ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}
