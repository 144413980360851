import {
  CloseRounded,
  ExplicitRounded,
  NoAdultContentRounded,
} from '@mui/icons-material'
import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import {socialSuggestions} from 'data'
import {Controller, useFormContext} from 'react-hook-form'
import {iSocialProfile} from 'types'

interface SocialCardProps {
  field: iSocialProfile
  index: number
  handleRemoveSocial: (index: number) => void
}

type FormValues = {
  nsfw: iSocialProfile[]
}

export function NsfwLinksCard({
  field,
  index,
  handleRemoveSocial,
}: SocialCardProps) {
  const {control, setValue} = useFormContext<FormValues>()

  return (
    <Paper sx={{borderRadius: 4}} elevation={0}>
      <Stack
        px={2}
        py={3}
        direction={{xs: 'column', md: 'row'}}
        spacing={2}
        alignItems="stretch"
      >
        <Controller
          name={`nsfw.${index}.title`}
          control={control}
          render={({field: {onChange, value}}) => (
            <Select
              value={value}
              onChange={e => {
                const selectedPlatform = socialSuggestions.find(
                  p => p.title === e.target.value,
                )
                onChange(e.target.value)
                if (selectedPlatform) {
                  setValue(`nsfw.${index}.title`, selectedPlatform.title)
                  setValue(`nsfw.${index}.href`, selectedPlatform.href)
                  setValue(`nsfw.${index}.nsfw`, selectedPlatform.nsfw)
                }
              }}
              displayEmpty
              renderValue={selected => {
                const selectedPlatform = socialSuggestions.find(
                  p => p.title === selected,
                )
                return (
                  <Box
                    sx={{display: 'flex', alignItems: 'center', height: '100%'}}
                  >
                    {selectedPlatform && selectedPlatform.icon}
                  </Box>
                )
              }}
              sx={{
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  height: '100%',
                },
              }}
            >
              {socialSuggestions
                .filter(v => v.nsfw)
                .map(platform => (
                  <MenuItem key={platform.key} value={platform.title}>
                    <Box display="flex" alignItems="center">
                      {platform.icon}
                      <Box sx={{ml: 1}}>{platform.title}</Box>
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          )}
        />
        <Controller
          name={`nsfw.${index}.href`}
          control={control}
          render={({field: _field, fieldState}) => (
            <TextField
              {..._field}
              label={field.title || 'URL'}
              variant="outlined"
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
            />
          )}
        />

        <Stack direction="row" alignItems="center" spacing={2}>
          <Controller
            name={`nsfw.${index}.nsfw`}
            control={control}
            render={({field}) => (
              <Checkbox
                {...field}
                checked={true}
                onChange={e => field.onChange(e.target.checked)}
                title={field.value ? '18+ Content' : 'No 18+ Content'}
                icon={<NoAdultContentRounded />}
                checkedIcon={<ExplicitRounded />}
                color="warning"
              />
            )}
          />
          <Box>
            <IconButton onClick={() => handleRemoveSocial(index)} color="error">
              <CloseRounded />
            </IconButton>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  )
}
