import {MenuRounded} from '@mui/icons-material'
import {Box, IconButton, Stack, SwipeableDrawer} from '@mui/material'
import {PancakeBioLogo} from 'assets'
import {drawerOpenState} from 'data'
import {Fragment} from 'react/jsx-runtime'
import {useSigninCheck} from 'reactfire'
import {useRecoilState} from 'recoil'
import {ROUTES} from 'router'
import {DrawerButton} from './DrawerButton'
export function DrawerMenu() {
  const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState)
  const {data: applicant} = useSigninCheck({
    requiredClaims: {applicant: true},
  })

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      // Allow only tab and shift keys to trigger the drawer close on keyboard event
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setDrawerOpen(open)
    }

  return (
    <Fragment>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuRounded />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{sx: {backgroundImage: 'none', p: 3}}}
      >
        <Stack justifyContent={'space-between'} height="100%">
          <Stack spacing={0.5} alignItems="center">
            <img
              src={PancakeBioLogo}
              alt="Pancake ID Logo"
              style={{maxWidth: 64, width: '100%', height: 'auto'}}
            />
            <Box pt={2} />
            <DrawerButton href={ROUTES.HOME}>Home</DrawerButton>
            {!applicant?.signedIn && (
              <DrawerButton href={ROUTES.JOIN}>Join</DrawerButton>
            )}
            <DrawerButton href={ROUTES.PROFILES}>Profiles</DrawerButton>
            <DrawerButton href={ROUTES.ACCOUNT}>Account</DrawerButton>
          </Stack>

          <Stack spacing={0.5}>
            <DrawerButton href={ROUTES.CONTACT}>Contact</DrawerButton>
            <DrawerButton href={ROUTES.PRIVACY}>Privacy Policy</DrawerButton>
            <DrawerButton href={ROUTES.TERMS}>
              Terms and Conditions
            </DrawerButton>
            <DrawerButton href={ROUTES.COOKIE}>Cookie Policy</DrawerButton>
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </Fragment>
  )
}
