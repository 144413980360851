import {Divider, Stack, Typography} from '@mui/material'
import {SocialLinks} from './SocialLinks'

export function Footer() {
  return (
    <Stack spacing={1} py={8}>
      <Divider />
      <Typography variant="body2" align="center">
        &copy; {new Date().getFullYear()} Pancake Bio - All rights reserved.
      </Typography>
      <Typography variant="caption" align="center" color="textSecondary">
        Pancake Bio is a trading name of Pancake Capital Limited. Pancake
        Capital Limited is a company registered in England and Wales with
        company number 15837649. Registered office: 1 Kings Arms Yard, City of
        London, EC2R 7AF.
      </Typography>
      <SocialLinks />
    </Stack>
  )
}
