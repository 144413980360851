import {Alert, Box, Button, Stack, Typography} from '@mui/material'
import {FileInputWithPreview, PancakeMascot} from 'components'
import {doc, getFirestore, setDoc, Timestamp} from 'firebase/firestore'
import {useApplication} from 'hooks/useApplication'
import {useDocUpload} from 'hooks/useDocUpload'
import React, {useEffect} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {useSearchParams} from 'react-router-dom'
import {useUser} from 'reactfire'

interface iIDs {
  issuedId: File[]
  existingIssuedId: string[]
  selfieWithId1: File[]
  existingSelfieWithId1: string[]
  selfieWithId2: File[]
  existingSelfieWithId2: string[]
}

export function IDsStep() {
  const {data: application, refetch} = useApplication()
  const {data: user} = useUser()
  const firestore = getFirestore()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [, setSearchParams] = useSearchParams()

  const {uploadFiles} = useDocUpload('kyc', application?.userId || '')

  const methods = useForm<iIDs>({
    mode: 'onChange',
    defaultValues: {
      issuedId: [],
      existingIssuedId: application?.issuedId || [],
      selfieWithId1: [],
      existingSelfieWithId1: application?.selfieWithId1 || [],
      selfieWithId2: [],
      existingSelfieWithId2: application?.selfieWithId2 || [],
    },
  })

  const {handleSubmit, setValue} = methods

  useEffect(() => {
    if (application) {
      methods.reset({
        issuedId: [],
        existingIssuedId: application.issuedId || [],
        selfieWithId1: [],
        existingSelfieWithId1: application.selfieWithId1 || [],
        selfieWithId2: [],
        existingSelfieWithId2: application.selfieWithId2 || [],
      })
    }
  }, [application, methods.reset])

  const handleFileChange = async (
    files: FileList,
    fieldName: keyof iIDs,
  ): Promise<string[]> => {
    const newFiles = Array.from(files)
    setValue(fieldName, newFiles as any)
    return []
  }

  const onSubmit = async (data: iIDs) => {
    if (isSubmitting || !application?.docId) return
    setIsSubmitting(true)

    try {
      const updatedApplication: {
        issuedId: string[]
        selfieWithId1: string[]
        selfieWithId2: string[]
      } = {
        issuedId: [],
        selfieWithId1: [],
        selfieWithId2: [],
      }

      const uploadAndMergeFiles = async (
        newFiles: File[],
        existingUrls: string[],
      ): Promise<string[]> => {
        if (newFiles.length > 0) {
          const dataTransfer = new DataTransfer()
          newFiles.forEach(file => dataTransfer.items.add(file))
          const fileListToUpload = dataTransfer.files

          const uploadedFiles = await uploadFiles(fileListToUpload)
          return [...existingUrls, ...uploadedFiles]
        }
        return existingUrls
      }

      updatedApplication.issuedId = await uploadAndMergeFiles(
        data.issuedId,
        data.existingIssuedId,
      )
      updatedApplication.selfieWithId1 = await uploadAndMergeFiles(
        data.selfieWithId1,
        data.existingSelfieWithId1,
      )
      updatedApplication.selfieWithId2 = await uploadAndMergeFiles(
        data.selfieWithId2,
        data.existingSelfieWithId2,
      )

      await setDoc(
        doc(firestore, 'applications', application.docId),
        {...updatedApplication, updatedAt: Timestamp.now()},
        {merge: true},
      )

      await refetch()
      console.log('IDs added to application document successfully')
      setSearchParams({mode: 'apply', step: 'payments'})
    } catch (error) {
      console.error('Error processing application:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="center">
          <PancakeMascot
            text={`Hey ${user?.displayName}, let's make sure you're you! Time to snap some pics of your ID.`}
          />
          <Alert severity="info">
            In order to perform or collaborate with others please provide your
            Selfie & IDs
          </Alert>
          <Typography variant="h6">Official Government ID</Typography>
          <FileInputWithPreview
            onUpload={files => handleFileChange(files, 'issuedId')}
            existingFiles={application?.issuedId}
            name={'issuedId'}
          />

          <Typography variant="h6">Selfie with ID (Optional)</Typography>
          <FileInputWithPreview
            onUpload={files => handleFileChange(files, 'selfieWithId1')}
            existingFiles={application?.selfieWithId1}
            name={'selfieWithId1'}
          />

          <Typography variant="h6">
            Another Selfie with ID (Optional)
          </Typography>
          <FileInputWithPreview
            onUpload={files => handleFileChange(files, 'selfieWithId2')}
            existingFiles={application?.selfieWithId2}
            name={'selfieWithId2'}
          />
        </Stack>

        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
          <Button
            color="inherit"
            onClick={() => setSearchParams({mode: 'apply', step: 'details'})}
            sx={{mr: 1}}
          >
            Back
          </Button>
          <Box sx={{flex: '1 1 auto'}} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Next
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}
