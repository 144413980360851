import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import {IconButton} from '@mui/material'
import MobileStepper from '@mui/material/MobileStepper'
import {useTheme} from '@mui/material/styles'

interface DotsMobileStepperProps {
  steps: number // Total number of steps
  activeStep: number // Currently active step
  handleNext: () => void // Function to handle the "Next" action
  handleBack: () => void // Function to handle the "Back" action
}

export function DotsMobileStepper({
  steps,
  activeStep,
  handleNext,
  handleBack,
}: DotsMobileStepperProps) {
  const theme = useTheme()

  return (
    <MobileStepper
      variant="dots"
      steps={steps}
      position="static"
      activeStep={activeStep}
      sx={{width: '100%'}}
      nextButton={
        <IconButton
          size="small"
          onClick={handleNext}
          disabled={activeStep === steps - 1}
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      }
      backButton={
        <IconButton
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
      }
    />
  )
}
