import {Visibility} from '@mui/icons-material'
import {Box, Button, Paper, Typography} from '@mui/material'

const getFileTypeFromUrl = (url: string): 'image' | 'pdf' | 'unknown' => {
  const pathname = new URL(url).pathname.toLowerCase()
  if (pathname.match(/\.(jpeg|jpg|gif|png)$/)) return 'image'
  if (pathname.match(/\.pdf$/)) return 'pdf'
  return 'unknown'
}

export function DocumentPreview({url}: {url: string}) {
  const fileType = getFileTypeFromUrl(url)

  console.log(fileType)

  return (
    <>
      <Paper
        sx={{
          width: 200,
          height: 200,
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
        }}
      >
        {fileType === 'image' ? (
          <img
            src={url}
            alt="Document preview"
            style={{
              width: '100%',
              height: '100%',
              maxWidth: 200,
              maxHeight: 200,
              objectFit: 'cover',
            }}
          />
        ) : fileType === 'pdf' ? (
          <iframe
            src={url}
            title="PDF preview"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
            }}
          />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            bgcolor="#f5f5f5"
            border="1px solid #ddd"
          >
            <Typography variant="body2">Unsupported Document</Typography>
          </Box>
        )}
      </Paper>
      <Button
        onClick={() => window.open(url, '_blank')}
        startIcon={<Visibility />}
        color="info"
      >
        View
      </Button>
    </>
  )
}
