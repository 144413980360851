// hooks/useProfile.ts
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {updateProfile} from 'firebase/auth'
import {doc, getDoc, updateDoc} from 'firebase/firestore'
import {
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage'
import {useAuth, useFirestore} from 'reactfire'
import {ProfileData} from 'types'

const fetchProfile = async (
  profileId: string | null,
  firestore: any,
): Promise<ProfileData> => {
  if (profileId === null) throw new Error('no profile id')
  const docRef = doc(firestore, 'profiles', profileId)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return {id: docSnap.id, ...docSnap.data()} as ProfileData
  } else {
    throw new Error('Profile not found')
  }
}

export function useProfile(profileId: string | null) {
  const firestore = useFirestore()

  return useQuery<ProfileData>({
    queryKey: ['profile', profileId],
    queryFn: () => fetchProfile(profileId, firestore),
    enabled: !!profileId,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })
}

interface UpdateProfileDetailsData {
  displayName: string
  description?: string
  [key: string]: any // Allow for additional properties
}

const updateProfileDetails = async (
  profileId: string,
  data: Partial<UpdateProfileDetailsData>,
  firestore: any,
): Promise<void> => {
  const docRef = doc(firestore, 'profiles', profileId)
  await updateDoc(docRef, data)
}

export function useUpdateProfileDetails(profileId: string) {
  const firestore = useFirestore()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: Partial<UpdateProfileDetailsData>) =>
      updateProfileDetails(profileId, data, firestore),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['profile', profileId]})
    },
  })
}

export function usePfpProfileUpload(docId: string) {
  const storage = getStorage()
  const auth = useAuth()
  const firestore = useFirestore()

  const updateProfileAndDocument = async (photoURL: string) => {
    if (!auth.currentUser) {
      throw new Error('No authenticated user found')
    }

    await updateProfile(auth.currentUser, {photoURL})

    const userDocRef = doc(firestore, 'users', docId)
    await updateDoc(userDocRef, {pfp: photoURL})
  }

  const uploadProfilePicture = async (file: File): Promise<string> => {
    try {
      if (!auth.currentUser) {
        throw new Error('No authenticated user found')
      }

      const ext = file.type.split('/')[1] || 'jpg'
      const storageRef = ref(storage, `pfp/${docId}.${ext}`)

      await uploadBytes(storageRef, file)

      const downloadURL = await getDownloadURL(storageRef)

      await updateProfileAndDocument(downloadURL)

      return downloadURL
    } catch (error) {
      console.error('Error uploading profile picture:', error)
      throw error
    }
  }

  const updateProfileWithExistingUrl = async (
    profileId: string,
    photoURL: string,
  ): Promise<void> => {
    console.log(profileId, photoURL)
    try {
      const profileRef = doc(firestore, 'profiles', profileId)
      await updateDoc(profileRef, {
        pfp: photoURL,
      })
    } catch (error) {
      console.error('Error updating profile document with existing URL:', error)
      throw error
    }
  }

  const copyExistingDisplayPicture = async (
    userId: string,
    profileId: string,
  ): Promise<string> => {
    try {
      if (!auth.currentUser) {
        throw new Error('No authenticated user found')
      }

      const sourceRef = ref(storage, `dp/${userId}`)

      // Get the download URL of the source file
      const sourceUrl = await getDownloadURL(sourceRef)

      // Fetch the image data
      const response = await fetch(sourceUrl)
      const blob = await response.blob()

      // Get metadata of the source file
      const metadata = await getMetadata(sourceRef)
      const contentType = metadata.contentType || 'image/jpeg'
      const ext = contentType.split('/')[1] || 'jpg'

      // Reference to the destination file
      const destRef = ref(storage, `pfp/${profileId}.${ext}`)

      // Upload the blob to the new location
      await uploadBytes(destRef, blob, {contentType})

      // Get the download URL of the copied file
      const downloadURL = await getDownloadURL(destRef)

      // Update profile and document
      await updateProfileAndDocument(downloadURL)

      // Update the Firestore document for the profile
      const profileRef = doc(firestore, 'profiles', profileId)
      await updateDoc(profileRef, {
        pfp: downloadURL,
      })

      return downloadURL
    } catch (error) {
      console.error('Error copying display picture:', error)
      throw error
    }
  }

  return {
    uploadProfilePicture,
    updateProfileWithExistingUrl,
    copyExistingDisplayPicture,
  }
}
