import {Logout} from '@mui/icons-material'
import {ListItemIcon, MenuItem} from '@mui/material'
import {signOut} from 'firebase/auth'
import {useAuth} from 'reactfire'
import {useLocalStorage} from 'usehooks-ts'

export function LogoutButton() {
  const auth = useAuth()
  const [, setIsPhoneVerified] = useLocalStorage('isPhoneVerified', false)
  const [, setPhoneNumber] = useLocalStorage('phoneNumber', '')
  const [, setVerified] = useLocalStorage('verificationId', '')
  const logout = async () => {
    await signOut(auth)
    //reset local storage
    setIsPhoneVerified(false)
    setPhoneNumber('')
    setVerified('')
  }

  return (
    <MenuItem onClick={logout}>
      <ListItemIcon>
        <Logout fontSize="small" />
      </ListItemIcon>
      Logout
    </MenuItem>
  )
}
