import * as Yup from 'yup'

export const sfwLinkSchema = Yup.object().shape({
  sfw: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        title: Yup.string().required('Platform is required'),
        href: Yup.string().required('URL is required'),
        nsfw: Yup.boolean().default(false),
      }),
    )
    .required()
    .min(1, 'At least one social link is required'),
})

export const nsfwLinkSchema = Yup.object().shape({
  nsfw: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        title: Yup.string().required('Platform is required'),
        href: Yup.string().required('URL is required'),
        nsfw: Yup.boolean().default(true), // Changed to true as these are NSFW links
      }),
    )
    .optional() // This makes the entire array optional
    .default([]), // Provides a default empty array
})
