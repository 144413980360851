import {ButtonBase, Typography, useTheme} from '@mui/material'
import {PancakeBioLogo} from 'assets'
import {useNavigate} from 'react-router-dom'

export function Logo() {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/')
  }
  const theme = useTheme()
  const isMobile = theme.breakpoints.down('sm')
  return (
    <ButtonBase
      sx={{flexDirection: 'row', p: 1, borderRadius: 2, alignItems: 'center'}}
      onClick={handleNavigate}
    >
      <img
        src={PancakeBioLogo}
        alt="Pancake ID Logo"
        style={{maxWidth: 64, width: '100%', height: 'auto'}}
      />
      <Typography variant="h6" ml={1}>
        {isMobile ? '' : 'Pancake '}Bio
      </Typography>
    </ButtonBase>
  )
}
