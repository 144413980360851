import {useMutation, useQueryClient} from '@tanstack/react-query'
import {deleteDoc, doc} from 'firebase/firestore'
import {useFirestore} from 'reactfire'

export function useDeleteProfile(profileId: string) {
  const firestore = useFirestore()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      const profileRef = doc(firestore, 'profiles', profileId)
      await deleteDoc(profileRef)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['profile', profileId],
      }) // Invalidate the query that fetches the deleted profile
      queryClient.invalidateQueries({queryKey: ['profile', profileId]}) // Invalidate any queries that fetch multiple profiles
    },
  })
}
