import {EditRounded} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import {DocumentPreview, PancakeMascot} from 'components'
import dayjs from 'dayjs'
import {doc, Timestamp, updateDoc} from 'firebase/firestore'
import {useApplication} from 'hooks'
import {useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useAuth, useFirestore} from 'reactfire'
import {ROUTES} from 'router'

const formatDateOfBirth = (dob: Timestamp | undefined) => {
  if (!dob) return 'Not provided'
  const dobDate = dob.toDate()
  const formattedDate = dayjs(dobDate).format('MMMM D, YYYY')
  const age = dayjs().diff(dobDate, 'year')
  return `${formattedDate} (Age: ${age})`
}

export function ReviewStep() {
  const navigate = useNavigate()
  const {data: application, refetch, isLoading} = useApplication()
  const [, setSearchParams] = useSearchParams()
  const auth = useAuth()
  const firestore = useFirestore()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [agreeToTerms, setAgreeToTerms] = useState(false)

  const handleSubmit = async () => {
    if (!auth.currentUser || isSubmitting || !application || !agreeToTerms)
      return
    setIsSubmitting(true)

    try {
      const applicationRef = doc(firestore, 'applications', application.docId)
      await updateDoc(applicationRef, {
        status: 'submitted',
        submittedAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        agreeToTerms: agreeToTerms,
        agreementDate: Timestamp.now(),
      })
      await refetch()

      setSearchParams({})
      await auth.currentUser?.getIdTokenResult(true)
      navigate(ROUTES.DASHBOARD)
    } catch (error) {
      console.error('Error submitting application:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isLoading) return <CircularProgress />

  return (
    <Stack>
      <PancakeMascot text="Let's review your application before submitting! 📝" />
      <Stack>
        <Divider>
          <Typography variant="overline" color="textSecondary">
            Account Details
          </Typography>
        </Divider>
        <Paper sx={{mb: 2}}>
          <Stack px={3}>
            <Stack direction="row" spacing={3} alignItems="center">
              <Avatar
                sx={{height: 140, width: 140}}
                src={auth?.currentUser?.photoURL ?? ''}
                alt="Profile Picture"
                variant="rounded"
              />
              <Stack spacing={0.5}>
                <Typography variant="h6">
                  {auth.currentUser?.displayName}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {auth.currentUser?.email}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {auth.currentUser?.phoneNumber}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
        <Button
          variant="outlined"
          startIcon={<EditRounded />}
          onClick={() => setSearchParams({mode: 'apply', step: 'account'})}
        >
          Edit Account Details
        </Button>

        <Stack mt={4}>
          <Divider>
            <Typography variant="overline" color="textSecondary">
              Personal Details
            </Typography>
          </Divider>
          <Paper sx={{mb: 2}}>
            <Stack px={3} spacing={3}>
              <List disablePadding>
                <ListItem>
                  <ListItemText
                    primary={`Stage Name`}
                    secondary={application?.stageName || 'Not provided'}
                  />
                </ListItem>
                <ListItem sx={{display: 'flex'}}>
                  <ListItemText
                    primary={`Legal Name`}
                    secondary={application?.legalName || 'Not provided'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`Address`}
                    secondary={application?.address || 'Not provided'}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={`Date of Birth`}
                    secondary={formatDateOfBirth(
                      application?.dateOfBirth as unknown as Timestamp,
                    )}
                  />
                </ListItem>
              </List>
            </Stack>
          </Paper>
          <Button
            variant="outlined"
            startIcon={<EditRounded />}
            onClick={() => setSearchParams({mode: 'apply', step: 'details'})}
          >
            Edit Personal Details
          </Button>
        </Stack>

        <Stack mt={4}>
          <Divider>
            <Typography variant="overline" color="textSecondary">
              Social Accounts
            </Typography>
          </Divider>
          <Stack mb={1}>
            <List disablePadding>
              {application?.socials?.map((social, index) => (
                <Paper key={`social-link-${index}`} sx={{mb: 1}}>
                  <ListItem disablePadding>
                    <ListItemButton sx={{px: 5}}>
                      <ListItemText
                        primary={social.title}
                        secondary={social.href}
                      />
                    </ListItemButton>
                  </ListItem>
                </Paper>
              ))}
            </List>
          </Stack>
          <Button
            variant="outlined"
            startIcon={<EditRounded />}
            onClick={() => setSearchParams({mode: 'apply', step: 'socials'})}
          >
            Edit Social Accounts
          </Button>
        </Stack>
      </Stack>

      <Stack mt={4}>
        <Divider>
          <Typography variant="overline" color="textSecondary">
            Documents
          </Typography>
        </Divider>

        <Stack
          direction="row"
          gap={3}
          flexWrap={'wrap'}
          mb={2}
          justifyContent="center"
          textAlign={'center'}
        >
          <Stack>
            <Typography variant="overline" color="textSecondary">
              Government IDs
            </Typography>
            {application?.issuedId &&
              application?.issuedId.map((id, index) => (
                <DocumentPreview
                  key={`document-render-issuedId-${index}`}
                  url={id}
                />
              ))}
          </Stack>
          <Stack>
            <Typography variant="overline" color="textSecondary">
              Selfie with ID (Front)
            </Typography>
            {application?.selfieWithId1 &&
              application?.selfieWithId1.map((id, index) => (
                <DocumentPreview
                  key={`document-render-selfieWithId1-${index}`}
                  url={id}
                />
              ))}
          </Stack>
          <Stack>
            <Typography variant="overline" color="textSecondary">
              Selfie with ID (Back)
            </Typography>
            {application?.selfieWithId2 &&
              application?.selfieWithId2.map((id, index) => (
                <DocumentPreview
                  key={`document-render-selfieWithId2-${index}`}
                  url={id}
                />
              ))}
          </Stack>
        </Stack>

        <Button
          variant="outlined"
          startIcon={<EditRounded />}
          onClick={() => setSearchParams({mode: 'apply', step: 'ids'})}
        >
          Edit Identity Documents
        </Button>
      </Stack>

      <Stack mt={4}>
        <Divider>
          <Typography variant="overline" color="textSecondary">
            Payment Details
          </Typography>
        </Divider>
        <Paper sx={{mb: 2}}>
          <Stack px={3}>
            <Stack spacing={0.5}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Bank"
                    secondary={application?.bankDetails?.bankName}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Account Holder"
                    secondary={application?.bankDetails?.accountHolderName}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Account Number"
                    secondary={application?.bankDetails?.accountNumber}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Sort Code"
                    secondary={application?.bankDetails?.sortCode}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="SWIFT Code"
                    secondary={application?.bankDetails?.swiftCode}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="IBAN"
                    secondary={application?.bankDetails?.iban}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Bank Country"
                    secondary={application?.bankDetails?.country}
                  />
                </ListItem>
              </List>
            </Stack>
          </Stack>
        </Paper>
        <Button
          variant="outlined"
          startIcon={<EditRounded />}
          onClick={() => setSearchParams({mode: 'apply', step: 'payments'})}
        >
          Edit Bank Details
        </Button>
      </Stack>

      <FormControlLabel
        sx={{mt: 2}}
        control={
          <Checkbox
            checked={agreeToTerms}
            onChange={e => setAgreeToTerms(e.target.checked)}
            color="primary"
          />
        }
        label="I agree to the Terms of Service and Use"
      />

      <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 4}}>
        <Button
          color="inherit"
          onClick={() => setSearchParams({mode: 'apply', step: 'payments'})}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || !agreeToTerms}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Application'}
        </Button>
      </Box>
    </Stack>
  )
}
