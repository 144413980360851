import {yupResolver} from '@hookform/resolvers/yup'
import {AddLinkRounded} from '@mui/icons-material'
import {Box, Button, CircularProgress, Stack, Typography} from '@mui/material'
import {SfwLinksCard} from 'components'
import {Timestamp} from 'firebase/firestore'
import {useApplication, useProfile, useUpdateProfileDetails} from 'hooks'
import React from 'react'
import {FormProvider, useFieldArray, useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {iSocialProfile} from 'types'
import {cleanupSocialLinks} from 'utils'
import {v4 as uuidv4} from 'uuid'
import {sfwLinkSchema} from './schema'

type FormValues = {
  sfw: iSocialProfile[]
}

interface SFWLinksProps {
  onBack: () => void
  onSuccess: () => void
}

export function SFWLinks({onBack, onSuccess}: SFWLinksProps) {
  const {docId} = useParams<{docId: string}>()
  const {data: application, isLoading: isLoadingApplication} = useApplication(
    'applications-approved',
  )
  const {data: profile, isLoading: isLoadingProfile} = useProfile(docId || null)
  const updateProfile = useUpdateProfileDetails(docId ?? '')

  const methods = useForm<FormValues>({
    resolver: yupResolver(sfwLinkSchema),
    defaultValues: {
      sfw: [],
    },
  })

  const {control, handleSubmit, reset} = methods

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'sfw',
  })

  React.useEffect(() => {
    if (profile?.sfw || application?.socials) {
      const profileSocials = profile?.sfw || []
      const applicationSocials = application?.socials || []

      // Combine and deduplicate socials, prioritizing profile data
      const combinedSocials = [
        ...profileSocials,
        ...applicationSocials.filter(
          appSocial =>
            !profileSocials.some(profSocial => profSocial.id === appSocial.id),
        ),
      ]

      // Clean up the combined socials
      const cleanedSocials = cleanupSocialLinks(combinedSocials)

      // Filter out NSFW links
      const sfwSocials = cleanedSocials.filter(social => !social.nsfw)

      reset({sfw: sfwSocials})
    }
  }, [profile, application, reset])

  const handleAddSocial = () => {
    append({id: uuidv4(), title: '', href: '', nsfw: false})
  }

  const handleRemoveSocial = (index: number) => {
    remove(index)
  }

  const onSubmit = (data: FormValues) => {
    // Clean up the links before submitting
    const cleanedData = {
      sfw: cleanupSocialLinks(data.sfw),
    }
    updateProfile.mutate(
      {...cleanedData, updatedAt: Timestamp.now()},
      {
        onSuccess: () => {
          onSuccess()
        },
      },
    )
    // Here you would typically update the profile and/or application data in your backend
  }

  if (isLoadingApplication || isLoadingProfile) {
    return <CircularProgress />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Typography variant="h6">
            Safe for Work (Not 18+ Social Accounts)
          </Typography>

          {fields.map((field, index) => (
            <SfwLinksCard
              key={field.id}
              field={field}
              index={index}
              handleRemoveSocial={handleRemoveSocial}
            />
          ))}

          <Button
            color="info"
            onClick={handleAddSocial}
            startIcon={<AddLinkRounded />}
          >
            Add Safe For Work (not 18+) Link
          </Button>

          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Button color="inherit" onClick={onBack} sx={{mr: 1}}>
              Back
            </Button>
            <Box sx={{flex: '1 1 auto'}} />

            <Button type="submit" variant="contained" sx={{mt: 2}}>
              Next
            </Button>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}
