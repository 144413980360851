// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: 'AIzaSyBRgPdm7Yj3PQcIzIXKsm6H8GngWf0-sv0',
  authDomain: 'pancake-bio.firebaseapp.com',
  projectId: 'pancake-bio',
  storageBucket: 'pancake-bio.appspot.com',
  messagingSenderId: '904769791117',
  appId: '1:904769791117:web:6b2b1f337aec6024eaeec4',
}

export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://admin.pancake.bio', // Adjust this to match the URL of your deployed app
  // This must be true for email link sign-in.
  handleCodeInApp: true,
}
