import {Alert, Button, Stack, TextField, Typography} from '@mui/material'
import {PancakeMascot} from 'components'
import {FirebaseError} from 'firebase/app'
import {usePhoneVerification, useRecaptcha} from 'hooks'
import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useUser} from 'reactfire'

interface OTCFormValues {
  verifyPhoneNumber: string
}

export function OneTimeCode({
  onSuccess,
  onBack,
}: {
  onSuccess: () => void
  onBack: () => void
}) {
  const {
    verifyOtpCode,
    requestNewOtp,
    error: otpError,
    setError: setOtpError,
    phoneNumber,
    isPhoneVerified,
    hasOngoingVerification,
  } = usePhoneVerification()

  const {
    control,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<OTCFormValues>({
    defaultValues: {
      verifyPhoneNumber: '',
    },
  })

  const inputRefs = useRef<Array<HTMLInputElement | null>>([])
  const {data: user} = useUser()

  const {resetRecaptcha} = useRecaptcha()

  const handleOtpSubmit = useCallback(
    async (otpCode: string) => {
      console.log('OTP Submitted:', otpCode)

      if (!otpCode || otpCode.length !== 6) {
        setOtpError('Please enter a valid 6-digit OTP.')
        return
      }

      if (!hasOngoingVerification) {
        setOtpError(
          'No ongoing verification. Please verify your phone number first.',
        )
        return
      }

      if (!user) {
        setOtpError('No user found. Please try again.')
        return
      }

      try {
        await verifyOtpCode(otpCode, user)
        onSuccess()
      } catch (error) {
        const e = error as FirebaseError
        console.log(error)
        if (e.code) {
          setOtpError(e.code)
        } else {
          setOtpError('Invalid OTP. Please try again.')
        }
      }
    },
    [hasOngoingVerification, setOtpError, verifyOtpCode, onSuccess],
  )

  const handleRequestNewOtp = useCallback(() => {
    console.log('Requesting new OTP...')
    resetRecaptcha()
    if (user && phoneNumber) {
      if (window.recaptchaVerifier === undefined)
        return setOtpError('reCAPTCHA not initialized. Please try again.')
      try {
        requestNewOtp(user, window.recaptchaVerifier)
      } catch (e) {
        console.error('Error sending new OTP:', e)
        setOtpError('Failed to send new OTP. Please try again.')
      }
    } else {
      let errorMessage = 'Unable to request new OTP. '
      if (!user) errorMessage += 'User not found. '
      if (!phoneNumber) errorMessage += 'Phone number not found. '
      if (!window.recaptchaVerifier)
        errorMessage += 'reCAPTCHA not initialized. '
      console.error(errorMessage)
      setOtpError(errorMessage + 'Please try again.')
    }
  }, [
    phoneNumber,
    user,
    requestNewOtp,
    resetRecaptcha,
    setOtpError,
    window.recaptchaVerifier,
  ])

  const handleOtpChange = useCallback(
    (index: number, value: string) => {
      if (/^\d$/.test(value) || value === '') {
        const currentOtp = getValues('verifyPhoneNumber') || ''
        const otpArray = currentOtp.split('')

        otpArray[index] = value
        const newOtp = otpArray.join('')
        setValue('verifyPhoneNumber', newOtp)

        if (value !== '' && index < 5) {
          inputRefs.current[index + 1]?.focus()
        }

        if (
          otpArray.every((digit: string) => digit !== '') &&
          otpArray.length === 6
        ) {
          handleOtpSubmit(newOtp)
        }
      }
    },
    [getValues, setValue, handleOtpSubmit],
  )

  const handlePaste = useCallback(
    (event: React.ClipboardEvent) => {
      const pastedData = event.clipboardData.getData('Text')
      if (/^\d{6}$/.test(pastedData)) {
        setValue('verifyPhoneNumber', pastedData)
        pastedData.split('').forEach((char, index) => {
          if (inputRefs.current[index]) {
            inputRefs.current[index]!.value = char
          }
        })
        handleOtpSubmit(pastedData)
      }
    },
    [handleOtpSubmit, setValue],
  )

  useEffect(() => {
    if (!phoneNumber || !hasOngoingVerification) {
      onBack()
    }
  }, [phoneNumber, hasOngoingVerification, onBack])

  const OtpInputs = useMemo(
    () => (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        onPaste={handlePaste}
      >
        {Array.from({length: 6}).map((_, index) => (
          <Controller
            key={index}
            name="verifyPhoneNumber"
            control={control}
            render={({field}) => (
              <TextField
                value={(field.value || '').split('')[index] || ''}
                onChange={e => handleOtpChange(index, e.target.value)}
                inputRef={el => (inputRefs.current[index] = el)}
                variant="outlined"
                autoFocus={index === 0}
                onKeyDown={e => {
                  if (e.key === 'Backspace') {
                    e.preventDefault()
                    console.log(field.value, index)
                    if (field.value !== '') {
                      handleOtpChange(index, '')
                      inputRefs.current[index - 1]?.focus()
                    }
                  }
                }}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                    inputProps: {
                      maxLength: 1,
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    },
                  },
                }}
                error={Boolean(errors.verifyPhoneNumber)}
                sx={{
                  width: '3rem',
                  textAlign: 'center',
                  input: {textAlign: 'center'},
                }}
              />
            )}
          />
        ))}
      </Stack>
    ),
    [control, errors, handleOtpChange],
  )

  return (
    <Stack spacing={2} alignItems="center">
      <PancakeMascot text="What's the secret code? 🤫" />
      {isPhoneVerified && (
        <>
          <Typography variant="body1" color="textSecondary">
            Enter the code sent to <strong>••••{phoneNumber?.slice(-4)}</strong>
          </Typography>
          {OtpInputs}
          {otpError && <Alert severity="error">{otpError}</Alert>}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleRequestNewOtp}
          >
            Request New OTP
          </Button>
          <Button variant="outlined" color="secondary" onClick={onBack}>
            Back
          </Button>
        </>
      )}
    </Stack>
  )
}
