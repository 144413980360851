import {PersonAddAltRounded} from '@mui/icons-material'
import {
  Alert,
  Button,
  CircularProgress,
  Grid2 as Grid,
  Stack,
  Typography,
} from '@mui/material'
import {ProfileCard} from 'components'
import {ProfileCreation} from 'forms'
import {useMemberProfiles} from 'hooks/useProfiles'
import {useNavigate} from 'react-router-dom'
import {ROUTES} from 'router'

export function Profiles() {
  const {data: profiles, isLoading, error} = useMemberProfiles()
  const navigate = useNavigate()

  if (isLoading) return <CircularProgress />
  if (error) return <Alert severity="error">Error: {error.message}</Alert>
  if (!profiles?.activeProfiles.length && !profiles?.inactiveProfiles.length)
    return <ProfileCreation />

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent={'space-between'}>
        <Typography variant="h5">Profiles</Typography>
        <Button
          startIcon={<PersonAddAltRounded />}
          onClick={() => navigate(ROUTES.PROFILE_CREATE)}
        >
          New Profile
        </Button>
      </Stack>
      <Stack spacing={0.5}>
        <Typography variant="overline">Active Profiles</Typography>

        {profiles?.activeProfiles && (
          <Grid container spacing={3}>
            {profiles?.activeProfiles?.map(profile => (
              <Grid size={{xs: 12, sm: 6, md: 4}} key={profile.docId}>
                <ProfileCard profile={profile} active />
              </Grid>
            ))}
          </Grid>
        )}

        {profiles?.activeProfiles?.length === 0 && (
          <Alert severity="info">No Active Profiles</Alert>
        )}
      </Stack>

      <Stack spacing={0.5}>
        <Typography variant="overline">Inactive Profiles</Typography>
        {profiles?.inactiveProfiles && (
          <Grid container spacing={3}>
            {profiles?.inactiveProfiles?.map(profile => (
              <Grid size={{xs: 12, sm: 6, md: 4}} key={profile.docId}>
                <ProfileCard profile={profile} />
              </Grid>
            ))}
          </Grid>
        )}
        {profiles?.inactiveProfiles?.length === 0 && (
          <Alert severity="info">No Inactive Profiles</Alert>
        )}
      </Stack>
    </Stack>
  )
}
