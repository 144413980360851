import {VisibilityOffRounded} from '@mui/icons-material'
import {Box, ButtonBase, Paper, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {useUser} from 'reactfire'
import {ProfileData} from 'types'

export function PublicProfileCard({profile}: {profile: ProfileData}) {
  const {displayName, pfp, slug, userId} = profile
  const {data: user} = useUser()
  const isOwner = user && user.uid === userId
  const navigate = useNavigate()

  return (
    <Paper
      sx={{
        '&:hover': {
          scale: '1.1',
          boxShadow: 'rgba(239,91,17,0.24) 0 0 16px 0px',
          zIndex: 1,
        },
        transition: 'all 0.3s ease-in-out',
        width: 200,
        height: 200,
      }}
    >
      <ButtonBase sx={{width: '100%'}} onClick={() => navigate(`/m/${slug}`)}>
        <Box
          height={200}
          alignItems={'flex-end'}
          justifyContent={'center'}
          display="flex"
          p={2}
          sx={{
            backgroundImage: `url(${pfp})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: 1,
          }}
          width={200}
        >
          {isOwner && !profile.active && (
            <Box
              width={200}
              height={200}
              bgcolor={'rgba(0,0,0,0.48)'}
              sx={{position: 'absolute', top: 0, left: 0}}
              display={'flex'}
              alignItems="center"
              justifyContent={'center'}
            >
              <VisibilityOffRounded color="warning" sx={{ml: 1}} />
            </Box>
          )}
          <Typography
            sx={{background: 'rgba(0,0,0,0.82)', p: 0.5, borderRadius: 1}}
            fontWeight={'medium'}
          >
            {displayName}
          </Typography>
        </Box>
      </ButtonBase>
    </Paper>
  )
}
