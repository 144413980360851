export const ROUTES = {
  HOME: '/',
  CONTACT: '/contact',
  COOKIE: '/cookie-policy',
  PRIVACY: '/privacy-policy',
  TERMS: '/terms-and-conditions',
  MEMBERS: '/m',
  MEMBER: '/m/:id',
  MEMBER_EDIT: '/m/:id/edit',
  ACCOUNT: '/account',
  DASHBOARD: '/dashboard',
  JOIN: '/join',
  LOGIN: '/login',
  MEMBERSHIP: '/membership',
  PROFILES: '/profiles',
  PROFILE_CREATE: '/profiles/create',
  PROFILE_EDIT: '/profiles/:slugId/:docId/edit',
}
