import {Button, Stack} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {PancakeMascot} from './PancakeMascot'

export function JoinPancake() {
  const navigate = useNavigate()
  const onClick = () => {
    navigate('/join')
  }

  return (
    <Stack alignItems={'center'} pt={4}>
      <PancakeMascot text={`Welcome to Pancake, registration is Open!`} />
      <Button
        onClick={onClick}
        variant="contained"
        fullWidth
        sx={{maxWidth: 480}}
      >
        Join Pancake
      </Button>
    </Stack>
  )
}
