import {CircularProgress} from '@mui/material'
import {ApplicantOnly} from 'forms'
import {Join, Membership} from 'pages'
import {useEffect} from 'react'
import {Outlet, useSearchParams} from 'react-router-dom'
import {useSigninCheck} from 'reactfire'

export function AuthLayout() {
  const [searchParams, setSearchParams] = useSearchParams()
  // Check for 'member' claim
  const {status: memberStatus, data: member} = useSigninCheck({
    requiredClaims: {member: true},
  })

  // Check for 'applicant' claim
  const {status: applicantStatus, data: applicant} = useSigninCheck({
    requiredClaims: {applicant: true},
  })

  useEffect(() => {
    // Assuming you have a function that handles login and you get a status of login success
    // if (member?.signedIn || applicant?.signedIn) {
    //   // Clear the URL query params after login is successful
    //   const urlWithoutQuery = window.location.origin + window.location.pathname
    //   window.history.replaceState({}, document.title, urlWithoutQuery) // Remove query params without reloading
    // }
    if (member?.signedIn || applicant?.signedIn) {
      const mode = searchParams.get('mode')

      // Check if the 'mode' is 'signIn' (indicating a Firebase magic link)
      if (mode === 'signIn') {
        // Create a new URLSearchParams object from the current search params
        const newSearchParams = new URLSearchParams(searchParams)

        // Remove the 'mode' parameter
        newSearchParams.delete('mode')

        // Remove any Firebase-specific parameters (adjust as needed)
        newSearchParams.delete('oobCode')
        newSearchParams.delete('apiKey')
        newSearchParams.delete('lang')

        // Set the new search params, preserving other query parameters
        setSearchParams(newSearchParams)
      }
    }
  }, [searchParams, setSearchParams, member?.signedIn, applicant?.signedIn])

  // While loading, show a spinner
  if (memberStatus === 'loading' || applicantStatus === 'loading')
    return <CircularProgress />

  // Helper functions to determine user roles
  const isMember = member.signedIn && member.hasRequiredClaims
  const isApplicantOnly =
    applicant.signedIn &&
    applicant.hasRequiredClaims &&
    !member.hasRequiredClaims
  const needsToApply = applicant.signedIn && !applicant.hasRequiredClaims

  // Render views based on role
  if (isMember) return <Outlet />
  if (isApplicantOnly) return <ApplicantOnly />
  if (needsToApply) return <Membership />

  // Default: User not signed in or does not meet any conditions
  return <Join />
}
