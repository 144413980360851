// hooks/useApplication.ts
import {useQuery} from '@tanstack/react-query' // Import useQuery from React Query
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import {useAuth, useFirestore} from 'reactfire' // Use the Firestore hook from reactfire
import {ApplicationDocType} from 'types'

const fetchMostRecentApplication = async (
  userId: string,
  firestore: any,
  col: string,
) => {
  const applicationsRef = collection(firestore, col)
  const q = query(
    applicationsRef,
    where('userId', '==', userId),
    orderBy('createdAt', 'desc'),
    orderBy('updatedAt', 'desc'),
    limit(1),
  )

  const querySnapshot = await getDocs(q)
  if (!querySnapshot.empty) {
    const mostRecentDoc = querySnapshot.docs[0]
    return {
      ...mostRecentDoc.data(),
      docId: mostRecentDoc.id, // Include the document ID
    } as ApplicationDocType
  } else {
    throw new Error('No application found for the current user.')
  }
}

export function useApplication(collection: string = 'applications') {
  const auth = useAuth()
  const firestore = useFirestore() // Access Firestore instance
  const userId = auth.currentUser?.uid // Get the authenticated user's ID

  return useQuery<ApplicationDocType | undefined>({
    queryKey: ['application', userId],
    queryFn: () => fetchMostRecentApplication(userId!, firestore, collection),
    enabled: !!userId,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })
}
