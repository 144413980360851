import TextField from '@mui/material/TextField'

import {usePlacesWidget} from 'react-google-autocomplete'
import {Controller, useFormContext} from 'react-hook-form'
import {ApplicationFormValues} from 'types'

export function GoogleMaps() {
  const {control} = useFormContext<ApplicationFormValues>() // Access the form context

  return (
    <Controller
      name="address" // Name of the field in the form
      control={control}
      defaultValue=""
      render={({field: {onChange, value}, fieldState: {error}}) => {
        // Use `usePlacesWidget` to get the ref for the Google Autocomplete
        const {ref: inputRef} = usePlacesWidget({
          apiKey: import.meta.env.VITE_GOOGLE_PLACE_API,
          onPlaceSelected: (place: google.maps.places.PlaceResult) => {
            console.log(place) // You can access place details here
            onChange(place.formatted_address) // Update the form value with the selected address
          },
          options: {
            types: ['address'], // Specify the types you want (e.g., geocode, address)
          },
        })

        return (
          <TextField
            label="Residential Address"
            fullWidth
            inputRef={inputRef} // Use the ref from usePlacesWidget
            value={value} // Controlled value
            onChange={onChange} // Call the default onChange handler
            variant="outlined"
            error={!!error}
            helperText={error ? error.message : ''}
            sx={{mb: 2}}
          />
        )
      }}
    />
  )
}
