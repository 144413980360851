import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker'
import dayjs, {Dayjs} from 'dayjs'
import {Controller, useFormContext} from 'react-hook-form'
import {iDetails} from 'types' // Ensure this is correctly pointing to your types

export function DobDatePicker() {
  const {control} = useFormContext<iDetails>()

  // Calculate the minimum date allowed (18 years before today)
  const maxDate = dayjs().subtract(18, 'year')

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name="dateOfBirth"
        control={control}
        render={({field}) => (
          <StaticDatePicker
            value={field.value ? dayjs(field.value) : null} // Ensure value is a Dayjs object or null
            onChange={(newValue: Dayjs | null) => field.onChange(newValue)} // Update form value
            maxDate={maxDate} // Set the max date to 18 years before today
            defaultValue={maxDate}
            slotProps={{
              toolbar: {toolbarFormat: 'D MMM YYYY'}, // Set the toolbar format
            }}
          />
        )}
      />
    </LocalizationProvider>
  )
}
