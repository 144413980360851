import {
  ApplicationVerifier,
  getAuth,
  linkWithCredential,
  PhoneAuthProvider,
  User,
} from 'firebase/auth'
import {useCallback, useState} from 'react'
import {useLocalStorage} from 'usehooks-ts'

export function usePhoneVerification() {
  const [phoneNumber, setPhoneNumber] = useLocalStorage<string>(
    'phoneNumber',
    '',
  )
  const [verificationId, setVerificationId] = useLocalStorage<string>(
    'verificationId',
    '',
  )
  const [isPhoneVerified, setIsPhoneVerified] = useLocalStorage<boolean>(
    'isPhoneVerified',
    false,
  )
  const [error, setError] = useState<string>('')

  const verifyPhoneNumber = useCallback(
    async (
      newPhoneNumber: string,
      user: User | null,
      recaptchaVerifier: ApplicationVerifier,
    ) => {
      try {
        if (!user) {
          throw new Error('User not authenticated')
        }

        const auth = getAuth()
        const phoneProvider = new PhoneAuthProvider(auth)
        const verificationId = await phoneProvider.verifyPhoneNumber(
          newPhoneNumber,
          recaptchaVerifier,
        )

        setPhoneNumber(newPhoneNumber)
        setVerificationId(verificationId)
        setIsPhoneVerified(!!verificationId)
        setError('')
        console.log('Verification code sent.')
        return {verificationId}
      } catch (error: any) {
        console.error('Error during phone number verification', error)
        if (error.code === 'auth/invalid-phone-number') {
          setError('Invalid phone number. Please check and try again.')
        } else {
          setError('An unexpected error occurred. Please try again.')
        }
        throw error
      }
    },
    [setPhoneNumber, setVerificationId, setIsPhoneVerified, setError],
  )

  // const handlePhoneNumberError = useCallback(
  //   async (
  //     error: any,
  //     phoneNumber: string,
  //     recaptchaVerifier: ApplicationVerifier,
  //   ) => {
  //     if (error.code === 'auth/provider-already-linked') {
  //       await handleProviderAlreadyLinked(phoneNumber, recaptchaVerifier)
  //     } else {
  //       setError('An unexpected error occurred. Please try again.')
  //     }
  //   },
  //   [],
  // )

  // const handleExistingPhoneNumber = useCallback(
  //   async (phoneNumber: string, recaptchaVerifier: ApplicationVerifier) => {
  //     const auth = getAuth()
  //     try {
  //       const signInResult = await signInWithPhoneNumber(
  //         auth,
  //         phoneNumber,
  //         recaptchaVerifier,
  //       )
  //       setVerificationId(signInResult.verificationId)
  //       setPhoneNumber(phoneNumber)
  //       setIsPhoneVerified(!!signInResult.verificationId)
  //       setError(
  //         'This phone number is already in use. Please verify to sign in.',
  //       )
  //       return signInResult
  //     } catch (signInError) {
  //       console.error('Error during phone number sign in', signInError)
  //       setError('Error during phone number sign in. Please try again.')
  //     }
  //   },
  //   [setVerificationId, setPhoneNumber],
  // )

  // const handleProviderAlreadyLinked = useCallback(
  //   async (phoneNumber: string, recaptchaVerifier: ApplicationVerifier) => {
  //     setError(
  //       'This phone number is already linked to another user. You will be logged in using this phone number.',
  //     )

  //     const auth = getAuth()
  //     try {
  //       const signInResult = await signInWithPhoneNumber(
  //         auth,
  //         phoneNumber,
  //         recaptchaVerifier,
  //       )
  //       setVerificationId(signInResult.verificationId)
  //       setError('')
  //     } catch (signInError) {
  //       console.error('Error during phone number login', signInError)
  //       setError('Error during phone number login. Please try again.')
  //     }
  //   },
  //   [setVerificationId],
  // )

  const verifyOtpCode = useCallback(
    async (otpCode: string, user: User) => {
      if (!verificationId) {
        setError(
          'No verification ID available. Please verify your phone number first.',
        )
        return
      }
      try {
        const credential = PhoneAuthProvider.credential(verificationId, otpCode)

        // Link the phone credential with the current user
        await linkWithCredential(user, credential)

        setIsPhoneVerified(true)
        setVerificationId('')
        console.log('Phone number verified and linked successfully.')
        return user
      } catch (error: any) {
        console.error('Error during OTP verification', error)
        if (error.code === 'auth/credential-already-in-use') {
          setError('This phone number is already linked to another account.')
        } else if (error.code === 'auth/invalid-verification-code') {
          setError('Invalid OTP. Please try again.')
        } else {
          setError('An unexpected error occurred. Please try again.')
        }
        throw error
      }
    },
    [verificationId, setIsPhoneVerified, setVerificationId, setError],
  )

  const requestNewOtp = useCallback(
    (user: User | null, recaptchaVerifier: ApplicationVerifier) => {
      if (phoneNumber) {
        return verifyPhoneNumber(phoneNumber, user, recaptchaVerifier)
      } else {
        setError(
          'No phone number available. Please enter your phone number first.',
        )
      }
    },
    [phoneNumber, verifyPhoneNumber],
  )

  return {
    verifyPhoneNumber,
    verifyOtpCode,
    requestNewOtp,
    error,
    setError,
    phoneNumber,
    isPhoneVerified,
    hasOngoingVerification: !!verificationId,
  }
}
