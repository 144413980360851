import {
  Box,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'

type LabeledCheckboxProps = {
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  primaryLabel: string
  secondaryLabel: string
  actionComponent?: React.ReactNode // Optional component to display on the right side
}

export function LabeledCheckbox({
  checked,
  onChange,
  primaryLabel,
  secondaryLabel,
  actionComponent,
}: LabeledCheckboxProps) {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation() // Prevent click from triggering action component
    onChange({
      target: {checked: !checked},
    } as React.ChangeEvent<HTMLInputElement>)
  }
  return (
    <ButtonBase
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'flex-start',
        justifyContent: 'space-between',
        borderRadius: 2,
      }}
      component="div"
    >
      <Stack
        onClick={handleClick}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        textAlign={'left'}
        p={2}
        flexGrow={1}
      >
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={onChange} />}
          label={
            <Box pl={2}>
              <Typography variant="body1" lineHeight={1}>
                {primaryLabel}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {secondaryLabel}
              </Typography>
            </Box>
          }
        />
      </Stack>
      {actionComponent && <Box pr={2}>{actionComponent}</Box>}
    </ButtonBase>
  )
}
