import {connectAuthEmulator, getAuth} from 'firebase/auth'
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore'
import {connectStorageEmulator, getStorage} from 'firebase/storage'
import {
  AuthProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
} from 'reactfire'

export function FirestoreComponents({children}: {children: React.ReactNode}) {
  const app = useFirebaseApp()
  const firestore = getFirestore(app)
  const auth = getAuth(app)
  const storage = getStorage(app)

  if (process.env.NODE_ENV !== 'production') {
    // Set up emulators
    connectFirestoreEmulator(firestore, '0.0.0.0', 8080)
    connectAuthEmulator(auth, 'http://0.0.0.0:9099')
    connectStorageEmulator(storage, '0.0.0.0', 9199)
  }

  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        <StorageProvider sdk={storage}>{children}</StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  )
}
