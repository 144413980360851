import {Avatar, Box, Fade, Link, Stack, Typography} from '@mui/material'
import {DotsMobileStepper, SignInWithEmail} from 'components'
import {benefits as _benefits, members} from 'data'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSigninCheck, useUser} from 'reactfire'
import {ROUTES} from 'router'

// Extend benefits to include member details
export const benefits = _benefits.map((benefit, index) => {
  const member = members[index % members.length] // Use modulo to repeat members if needed
  return {
    ...benefit,
    memberName: member.name,
    memberProfileLink: member.profileLink,
    memberImagePath: member.imagePath,
  }
})

const wh = 200

export function Join() {
  const [currentIndex, setCurrentIndex] = useState(0) // State to keep track of the current slide index
  const [inProp, setInProp] = useState(true) // State to manage fade effect
  const {data: user} = useUser()

  const {data: member} = useSigninCheck({
    requiredClaims: {member: true},
  })

  const navigate = useNavigate()

  // Automatic interval to cycle through slides every 7.5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext() // Use the handleNext function to update the slide index
    }, 7500)

    return () => clearInterval(intervalId) // Cleanup interval on component unmount
  }, [])

  useEffect(() => {
    if (member.hasRequiredClaims) return navigate(ROUTES.PROFILES)
  }, [member])

  // Function to handle going to the next slide
  const handleNext = () => {
    setInProp(false) // Trigger fade-out
    setTimeout(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % benefits.length) // Update the current index
      setInProp(true) // Trigger fade-in
    }, 300) // Short delay for fade-out before changing content
  }

  // Function to handle going to the previous slide
  const handleBack = () => {
    setInProp(false) // Trigger fade-out
    setTimeout(() => {
      setCurrentIndex(
        prevIndex => (prevIndex - 1 + benefits.length) % benefits.length,
      ) // Update the current index
      setInProp(true) // Trigger fade-in
    }, 300) // Short delay for fade-out before changing content
  }

  return (
    <Stack spacing={2} alignItems="center" textAlign={'center'} sx={{mt: -6}}>
      {/* Slider for Member Details */}
      <Fade in={inProp} timeout={500}>
        <Stack spacing={0} alignItems={'center'}>
          <Avatar
            src={benefits[currentIndex].memberImagePath}
            alt={benefits[currentIndex].memberName}
            sx={{width: wh, height: wh}}
          />
          <Typography variant="h6">
            {benefits[currentIndex].memberName}
          </Typography>
          <Typography variant="caption">
            <Link href={benefits[currentIndex].memberProfileLink}>
              {benefits[currentIndex].memberProfileLink}
            </Link>
          </Typography>
        </Stack>
      </Fade>

      {/* Sign-in component */}
      {!user && (
        <Box maxWidth={480} width="100%">
          <SignInWithEmail />
        </Box>
      )}

      {/* Slider for Benefits */}
      <Fade in={inProp} timeout={500}>
        <Stack
          key={benefits[currentIndex].title}
          spacing={1}
          alignItems={'center'}
        >
          <Typography variant="h6" maxWidth={480} textAlign={'center'}>
            {benefits[currentIndex].title}
          </Typography>
          <Typography maxWidth={480} textAlign={'center'}>
            {benefits[currentIndex].description}
          </Typography>
        </Stack>
      </Fade>

      {/* Stepper Component for Navigation */}
      <Box maxWidth={480}>
        <DotsMobileStepper
          steps={benefits.length} // Total number of steps
          activeStep={currentIndex} // Current active step
          handleNext={handleNext} // Handle "Next" button click
          handleBack={handleBack} // Handle "Back" button click
        />
      </Box>
    </Stack>
  )
}
