// SlugStep.tsx
import {yupResolver} from '@hookform/resolvers/yup'
import {Button, InputAdornment, Link, Stack, TextField} from '@mui/material'
import {PancakeMascot} from 'components'
import {profileIdState} from 'data'
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  Timestamp,
  where,
} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {Link as RLink} from 'react-router-dom'
import {useUser} from 'reactfire'
import {useSetRecoilState} from 'recoil'
import {useDebounceValue} from 'usehooks-ts'
import * as yup from 'yup'

interface SlugStepProps {
  onSuccess: (profileId: string, slug: string) => void
}

const url = import.meta.env.PROD
  ? 'https://pancake.bio'
  : 'https://127.0.0.1:5173'

const schema = yup.object().shape({
  slug: yup
    .string()
    .required('Slug is required')
    .matches(/^[a-z0-9-]+$/, 'Use only lowercase letters, numbers, and hyphens')
    .min(3, 'Slug must be at least 3 characters')
    .max(30, 'Slug must not exceed 30 characters'),
})

export default function SlugStep({onSuccess}: SlugStepProps) {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: {errors},
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  })
  const {data: user} = useUser()
  const [isChecking, setIsChecking] = useState(false)
  const [isUnique, setIsUnique] = useState<boolean | null>(null)
  const setProfileId = useSetRecoilState(profileIdState)
  const slug = watch('slug')

  useEffect(() => {
    if (user?.displayName) {
      setValue('slug', user.displayName.toLowerCase().replace(/\s+/g, '-'))
    }
  }, [user, setValue])

  const [debouncedSlug, setDebouncedSlug] = useDebounceValue(slug, 500)

  useEffect(() => {
    const checkSlugUniqueness = async () => {
      if (debouncedSlug && debouncedSlug.length >= 3) {
        setIsChecking(true)
        const firestore = getFirestore()
        const slugQuery = query(
          collection(firestore, 'profiles'),
          where('slug', '==', debouncedSlug),
        )
        const querySnapshot = await getDocs(slugQuery)
        setIsUnique(querySnapshot.empty)
        setIsChecking(false)
      } else {
        setIsUnique(null)
      }
    }

    checkSlugUniqueness()
  }, [debouncedSlug])

  const onSubmit = async (data: {slug: string}) => {
    if (!isUnique) {
      setError('slug', {
        type: 'manual',
        message: 'This profile name is already taken. Please choose another.',
      })
      return
    }

    try {
      const docRef = await addDoc(collection(getFirestore(), 'profiles'), {
        slug: data.slug,
        userId: user?.uid,
        createdAt: Timestamp.now(),
        active: false,
      })
      setProfileId(docRef.id)
      onSuccess(docRef.id, data.slug)
    } catch (error) {
      console.error('Error creating profile document:', error)
      setError('slug', {
        type: 'manual',
        message:
          'An error occurred while creating the profile. Please try again.',
      })
    }
  }

  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSlug = e.target.value
    setValue('slug', newSlug)
    setDebouncedSlug(newSlug)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1}>
        <PancakeMascot
          text={`Let's name your public profile, tip: use your brand name!`}
        />
        <TextField
          {...register('slug')}
          label="Profile Name"
          variant="outlined"
          fullWidth
          error={!!errors.slug}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  {}
                  /m/
                </InputAdornment>
              ),
            },
          }}
          sx={{mb: 2}}
          onChange={handleSlugChange}
          helperText={
            errors.slug?.message ||
            (isChecking
              ? 'Checking availability...'
              : isUnique === false
                ? 'This profile name is already taken.'
                : isUnique === true
                  ? 'This profile name is available!'
                  : '')
          }
        />

        <Link
          component={RLink}
          to={`/m/${slug}`}
          underline="hover"
          color={
            isChecking
              ? 'warning'
              : isUnique === false
                ? 'error'
                : isUnique === true
                  ? 'success'
                  : 'info'
          }
        >
          {url}/m/{slug}
        </Link>
        <Button
          type="submit"
          variant="contained"
          disabled={!isUnique || isChecking}
        >
          Create Profile
        </Button>
      </Stack>
    </form>
  )
}
