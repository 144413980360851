import {
  Explicit,
  Facebook,
  Instagram,
  Language,
  LinkedIn,
  Pinterest,
  Reddit,
  Telegram,
  WhatsApp,
  X,
  YouTube,
} from '@mui/icons-material'

export const socialSuggestions = [
  {
    title: 'Instagram',
    icon: <Instagram />,
    key: 'instagram',
    href: 'https://instagram.com/',
    placeholder: 'https://instagram.com/username',
    nsfw: false,
  },
  {
    title: 'Facebook',
    icon: <Facebook />,
    key: 'facebook',
    href: 'https://facebook.com/',
    placeholder: 'https://facebook.com/username',
    nsfw: false,
  },
  {
    title: 'X',
    icon: <X />,
    key: 'X',
    href: 'https://x.com/',
    placeholder: 'https://x.com/username',
    nsfw: false,
  },
  {
    title: 'TikTok',
    icon: <Explicit />,
    key: 'tiktok',
    href: 'https://tiktok.com/@',
    placeholder: 'https://tiktok.com/@username',
    nsfw: false,
  },
  {
    title: 'OnlyFans',
    icon: <Explicit />,
    key: 'onlyfans',
    href: 'https://onlyfans.com/',
    placeholder: 'https://onlyfans.com/username',
    nsfw: true,
  },
  {
    title: 'YouTube',
    icon: <YouTube />,
    key: 'youtube',
    href: 'https://youtube.com/@',
    placeholder: 'https://youtube.com/@username',
    nsfw: false,
  },
  {
    title: 'LinkedIn',
    icon: <LinkedIn />,
    key: 'linkedin',
    href: 'https://linkedin.com/in/',
    placeholder: 'https://linkedin.com/in/username',
    nsfw: false,
  },
  {
    title: 'Twitch',
    icon: <Explicit />,
    key: 'twitch',
    href: 'https://twitch.tv/',
    placeholder: 'https://twitch.tv/username',
    nsfw: false,
  },
  {
    title: 'Reddit',
    icon: <Reddit />,
    key: 'reddit',
    href: 'https://reddit.com/user/',
    placeholder: 'https://reddit.com/user/username',
    nsfw: false,
  },
  {
    title: 'Snapchat',
    icon: <Explicit />,
    key: 'snapchat',
    href: 'https://snapchat.com/add/',
    placeholder: 'https://snapchat.com/add/username',
    nsfw: false,
  },
  {
    title: 'Pinterest',
    icon: <Pinterest />,
    key: 'pinterest',
    href: 'https://pinterest.com/',
    placeholder: 'https://pinterest.com/username',
    nsfw: false,
  },
  {
    title: 'WhatsApp',
    icon: <WhatsApp />,
    key: 'whatsapp',
    href: 'https://wa.me/',
    placeholder: 'https://wa.me/phonenumber',
    nsfw: false,
  },
  {
    title: 'Telegram',
    icon: <Telegram />,
    key: 'telegram',
    href: 'https://t.me/',
    placeholder: 'https://t.me/username',
    nsfw: false,
  },
  {
    title: 'Discord',
    icon: <Explicit />,
    key: 'discord',
    href: 'https://discord.gg/',
    placeholder: 'https://discord.gg/invite-code',
    nsfw: false,
  },
  {
    title: 'Custom',
    icon: <Language />,
    key: 'custom',
    href: '',
    placeholder: 'https://',
    nsfw: false,
  },
  {
    title: 'Custom',
    icon: <Language />,
    key: 'custom-2',
    href: '',
    placeholder: 'https://',
    nsfw: true,
  },
  {
    title: 'Scrile Connect',
    icon: <Language />,
    key: 'scrileconnect',
    href: 'https://scrile.com/',
    placeholder: 'https://scrile.com/username',
    nsfw: false,
  },
  {
    title: 'Fansly',
    icon: <Language />,
    key: 'fansly',
    href: 'https://fansly.com/',
    placeholder: 'https://fansly.com/username',
    nsfw: true,
  },
  {
    title: 'FanCentro',
    icon: <Language />,
    key: 'fancentro',
    href: 'https://fancentro.com/',
    placeholder: 'https://fancentro.com/username',
    nsfw: true,
  },
  {
    title: 'MYM.fans',
    icon: <Language />,
    key: 'mymfans',
    href: 'https://mym.fans/',
    placeholder: 'https://mym.fans/username',
    nsfw: true,
  },
  {
    title: 'JustForFans',
    icon: <Language />,
    key: 'justforfans',
    href: 'https://justforfans.com/',
    placeholder: 'https://justforfans.com/username',
    nsfw: true,
  },
  {
    title: 'Fanvue',
    icon: <Language />,
    key: 'fanvue',
    href: 'https://fanvue.com/',
    placeholder: 'https://fanvue.com/username',
    nsfw: true,
  },
  {
    title: 'Patreon',
    icon: <Language />,
    key: 'patreon',
    href: 'https://www.patreon.com/',
    placeholder: 'https://www.patreon.com/username',
    nsfw: false,
  },
  {
    title: 'Unlockd',
    icon: <Language />,
    key: 'unlockd',
    href: 'https://unlockd.com/',
    placeholder: 'https://unlockd.com/username',
    nsfw: true,
  },
  {
    title: 'ManyVids',
    icon: <Language />,
    key: 'manyvids',
    href: 'https://www.manyvids.com/',
    placeholder: 'https://www.manyvids.com/Profile/username',
    nsfw: true,
  },
  {
    title: 'LoyalFans',
    icon: <Language />,
    key: 'loyalfans',
    href: 'https://loyalfans.com/',
    placeholder: 'https://loyalfans.com/username',
    nsfw: true,
  },
  {
    title: 'Unfiltrd',
    icon: <Language />,
    key: 'unfiltrd',
    href: 'https://unfiltrd.com/',
    placeholder: 'https://unfiltrd.com/username',
    nsfw: true,
  },
]
