import {Box, Button, Stack, TextField, Typography} from '@mui/material'
import {DobDatePicker, GoogleMaps, PancakeMascot} from 'components'
import dayjs, {Dayjs} from 'dayjs'
import {doc, getFirestore, setDoc, Timestamp} from 'firebase/firestore'
import {useApplication} from 'hooks/useApplication'
import React from 'react'
import {Controller, FormProvider, useForm} from 'react-hook-form'
import {useSearchParams} from 'react-router-dom'
import {useUser} from 'reactfire'
import {iDetails} from 'types'

export function DetailsStep() {
  const {data: application, refetch} = useApplication()
  const {data: user} = useUser()
  const firestore = getFirestore()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [, setSearchParams] = useSearchParams()
  const [isFormComplete, setIsFormComplete] = React.useState(false)

  const methods = useForm<iDetails>({
    mode: 'onChange',
    defaultValues: {
      stageName: '',
      legalName: '',
      dateOfBirth: null,
      address: '',
    },
  })

  const {
    control,
    formState: {errors, isDirty, isValid},
    reset,
    getValues,
    watch,
  } = methods

  React.useEffect(() => {
    if (application) {
      reset({
        stageName: application.stageName || '',
        legalName: application.legalName || '',
        dateOfBirth: application.dateOfBirth
          ? dayjs(
              application.dateOfBirth instanceof Date
                ? application.dateOfBirth
                : (application.dateOfBirth as Timestamp).toDate(),
            )
          : null,
        address: application.address || '',
      })
    }
  }, [application, reset])

  React.useEffect(() => {
    const subscription = watch(() => {
      const formData = getValues()
      const isComplete =
        !!formData.stageName &&
        !!formData.legalName &&
        !!formData.dateOfBirth &&
        !!formData.address
      setIsFormComplete(isComplete)
    })
    return () => subscription.unsubscribe()
  }, [watch, getValues])

  const submitToFirestore = async (data: iDetails) => {
    if (!application?.docId) return

    const formattedDateOfBirth = data.dateOfBirth
      ? (data.dateOfBirth as Dayjs).toDate()
      : null

    const formattedAddress =
      typeof data.address === 'object' && data.address !== null
        ? (data.address as google.maps.places.PlaceResult).formatted_address
        : data.address

    const updatedApplication: Partial<iDetails> = {
      stageName: data.stageName,
      legalName: data.legalName,
      dateOfBirth: formattedDateOfBirth,
      address: formattedAddress,
    }

    await setDoc(
      doc(firestore, 'applications', application.docId),
      {...updatedApplication, updatedAt: Timestamp.now()},
      {merge: true},
    )
    await refetch()
    console.log('Details added to application document successfully')
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (isSubmitting) return
    setIsSubmitting(true)

    try {
      const formData = getValues()
      const hasChanged = isDirty && isValid

      console.log('Form Data:', formData)
      console.log('Has Changed:', hasChanged)
      console.log('Is Complete:', isFormComplete)

      if (hasChanged || !application) {
        await submitToFirestore(formData)
      }

      setSearchParams({mode: 'apply', step: 'ids'})
    } catch (error) {
      console.error('Error processing application:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const isExistingDataComplete =
    !!application?.stageName &&
    !!application?.legalName &&
    !!application?.dateOfBirth &&
    !!application?.address

  const isNextButtonEnabled = isFormComplete || isExistingDataComplete

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit}>
        <Stack spacing={3} alignItems="center">
          <PancakeMascot
            text={`God... ${user?.displayName} I like you, lets get more intimate, tell me who you "REALLY" are 😳`}
          />
          <Controller
            name="stageName"
            control={control}
            rules={{required: 'Stage Name is required'}}
            render={({field}) => (
              <TextField
                {...field}
                label="Stage Name"
                variant="outlined"
                fullWidth
                error={!!errors.stageName}
                helperText={errors.stageName?.message}
              />
            )}
          />

          <Controller
            name="legalName"
            control={control}
            rules={{required: 'Legal Name is required'}}
            render={({field}) => (
              <TextField
                {...field}
                label="Legal Name (as on issued ID)"
                variant="outlined"
                fullWidth
                error={!!errors.legalName}
                helperText={errors.legalName?.message}
              />
            )}
          />

          <GoogleMaps />
          <Box maxWidth={320} width="100%" margin={'0 auto'}>
            <Typography variant="overline" color="textSecondary">
              Date of Birth
            </Typography>
            <DobDatePicker />
          </Box>
        </Stack>
        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
          <Button
            color="inherit"
            onClick={() => setSearchParams({mode: 'apply', step: 'socials'})}
            sx={{mr: 1}}
          >
            Back
          </Button>
          <Box sx={{flex: '1 1 auto'}} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting || !isNextButtonEnabled}
          >
            Next
          </Button>
        </Box>
      </form>
    </FormProvider>
  )
}
