import {useQuery} from '@tanstack/react-query'
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore'

import {ProfileData} from 'types' // Adjust the import path as needed

const fetchPublicProfiles = async (firestore: any) => {
  const profilesRef = collection(firestore, 'profiles')
  const publicProfilesQuery = query(profilesRef, where('active', '==', true))
  const publicProfilesSnapshot = await getDocs(publicProfilesQuery)

  return publicProfilesSnapshot.docs.map(doc => ({
    ...(doc.data() as Omit<ProfileData, 'docId'>),
    docId: doc.id,
  }))
}

export function usePublicProfiles() {
  const firestore = getFirestore()

  return useQuery<ProfileData[]>({
    queryKey: ['publicProfiles'],
    queryFn: () => fetchPublicProfiles(firestore),
  })
}
