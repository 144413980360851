import {Alert, Button, Stack} from '@mui/material'
import {PancakeMascot} from 'components'
import {usePhoneVerification, useRecaptcha} from 'hooks'
import {MuiTelInput} from 'mui-tel-input'
import {Controller, useForm} from 'react-hook-form'
import {useUser} from 'reactfire'

interface PhoneNumberFormValues {
  phoneNumber: string
}

export function PhoneNumber({onSuccess}: {onSuccess: () => void}) {
  const {
    verifyPhoneNumber,
    error,
    setError,
    phoneNumber: storedPhoneNumber,
  } = usePhoneVerification()

  const {control, handleSubmit, watch} = useForm<PhoneNumberFormValues>({
    defaultValues: {
      phoneNumber: storedPhoneNumber,
    },
  })

  const {data: user} = useUser()
  const phoneNumber = watch('phoneNumber')
  useRecaptcha()

  const onSubmit = async (data: PhoneNumberFormValues) => {
    setError('')
    if (user && data.phoneNumber) {
      try {
        await verifyPhoneNumber(
          data.phoneNumber,
          user,
          window.recaptchaVerifier,
        )
        onSuccess()
      } catch (error) {
        setError((error as Error).message)
      }
    } else {
      setError('Please enter a valid phone number.')
    }
  }

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <PancakeMascot text=" hidden pics? 👀 🥵 ...what's your phone number 🤙🏽?" />
      <Controller
        name="phoneNumber"
        control={control}
        rules={{required: 'Phone number is required'}}
        render={({field, fieldState: {error: fieldError}}) => (
          <MuiTelInput
            {...field}
            label="Telephone"
            variant="outlined"
            fullWidth
            error={!!fieldError}
            helperText={fieldError?.message}
            defaultCountry="GB"
          />
        )}
      />

      <Button
        type="submit"
        variant="outlined"
        color="primary"
        disabled={!phoneNumber}
      >
        Confirm Phone Number
      </Button>

      {error && <Alert severity="error">{error}</Alert>}
      <div id="recaptcha-container" />
    </Stack>
  )
}
