import {Box, Paper, Stack, Typography} from '@mui/material'
import {PancakeBioLogo} from 'assets'

export function PancakeMascot({img, text}: {img?: string; text: string}) {
  return (
    <Stack direction="row" spacing={2} alignItems={'flex-end'} pb={2}>
      <img
        src={img ?? PancakeBioLogo}
        style={{width: 100, height: 'auto', maxWidth: '100%'}}
      />
      <Paper sx={{borderRadius: 3}}>
        <Box p={2}>
          <Typography textAlign={'left'}>{text}</Typography>
        </Box>
      </Paper>
    </Stack>
  )
}
