// hooks/useMemberProfiles.ts
import {useQuery} from '@tanstack/react-query'
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore'
import {useAuth, useFirestore} from 'reactfire'
import {ProfileDocType} from '../types' // Adjust the import path as needed

const fetchMemberProfiles = async (userId: string) => {
  const firestore = getFirestore()
  const profilesRef = collection(firestore, 'profiles')
  const userProfilesQuery = query(profilesRef, where('userId', '==', userId))
  const userProfilesSnapshot = await getDocs(userProfilesQuery)

  const profiles = userProfilesSnapshot.docs.map(doc => ({
    ...(doc.data() as Omit<ProfileDocType, 'docId'>),
    docId: doc.id,
  }))

  const activeProfiles = profiles.filter(profile => profile.active === true)
  const inactiveProfiles = profiles.filter(profile => profile.active === false)

  return {
    activeProfiles,
    inactiveProfiles,
  }
}

export function useMemberProfiles() {
  const auth = useAuth()
  const userId = auth.currentUser?.uid

  return useQuery<{
    activeProfiles: ProfileDocType[]
    inactiveProfiles: ProfileDocType[]
  }>({
    queryKey: ['memberProfiles', userId],
    queryFn: () => fetchMemberProfiles(userId!),
    enabled: !!userId,
  })
}

const fetchActiveProfiles = async (firestore: any) => {
  const profilesRef = collection(firestore, 'profiles')
  const activeProfilesQuery = query(profilesRef, where('active', '==', true))
  const activeProfilesSnapshot = await getDocs(activeProfilesQuery)

  return activeProfilesSnapshot.docs.map(doc => ({
    ...(doc.data() as Omit<ProfileDocType, 'docId'>),
    docId: doc.id,
  }))
}

export function useActiveProfiles() {
  const firestore = useFirestore()

  return useQuery<ProfileDocType[]>({
    queryKey: ['activeProfiles'],
    queryFn: () => fetchActiveProfiles(firestore),
  })
}
