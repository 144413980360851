import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import {Step, StepLabel, Stepper, useMediaQuery, useTheme} from '@mui/material'
import Button from '@mui/material/Button'
import MobileStepper from '@mui/material/MobileStepper'

interface ResponsiveStepperProps {
  steps: string[]
  activeStep: number
  handleStepClick: (index: number) => void
}

export function ResponsiveStepper({
  steps,
  activeStep,
  handleStepClick,
}: ResponsiveStepperProps): JSX.Element {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleNext = (): void => {
    handleStepClick(Math.min(activeStep + 1, steps.length - 1))
  }

  const handleBack = (): void => {
    handleStepClick(Math.max(activeStep - 1, 0))
  }

  if (isMobile) {
    return (
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        sx={{
          flexGrow: 1,
          width: '100%',
          maxWidth: 400,
          margin: '0 auto',
        }}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    )
  }

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => (
        <Step key={label} onClick={() => handleStepClick(index)}>
          <StepLabel>
            <Button
              size="small"
              color={index === activeStep ? 'primary' : 'inherit'}
            >
              {label}
            </Button>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
