import {useCallback, useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'

interface ScrollToTopOptions {
  smooth?: boolean
  autoScroll?: boolean
}

export function useScrollToTop({
  smooth = true,
  autoScroll = true,
}: ScrollToTopOptions = {}) {
  const location = useLocation()
  const previousLocation = useRef(location)

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: smooth ? 'smooth' : 'auto',
    })
  }, [smooth])

  useEffect(() => {
    if (
      autoScroll &&
      (location.pathname !== previousLocation.current.pathname ||
        location.search !== previousLocation.current.search)
    ) {
      scrollToTop()
    }
    previousLocation.current = location
  }, [location, scrollToTop, autoScroll])

  return scrollToTop
}
