import {Stack} from '@mui/material'
import {DrawerMenu} from './DrawerMenu'
import {ProfileButton} from './ProfileButton'

export function Navigation() {
  return (
    <Stack direction="row" spacing={0.5} alignItems={'center'}>
      <ProfileButton />
      <DrawerMenu />
    </Stack>
  )
}
