import {createTheme} from '@mui/material'

export const theme = createTheme({
  typography: {
    // allVariants: {
    //   fontFamily: `Salsa, cursive`,
    // },
    fontFamily: `Salsa, cursive`,
    subtitle1: {
      fontFamily: 'Roboto',
    },
    subtitle2: {
      fontFamily: 'Roboto',
    },
    body1: {
      fontFamily: 'Roboto',
    },
    body2: {
      fontFamily: 'Roboto',
    },
    caption: {
      fontFamily: 'Roboto',
    },
    overline: {
      fontFamily: 'Roboto',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#EF5B11',
    },
    secondary: {
      main: '#83B462',
    },
    background: {
      default: '#1A1A1A',
      paper: '#333',
    },
  },
})
