import {Box} from '@mui/material'
import {isOtcState} from 'data'
import {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {OneTimeCode} from './OneTimeCode'
import {PhoneNumber} from './PhoneNumber'

export function RegistrationWizard() {
  const [isOtc, setIsOtc] = useRecoilState(isOtcState)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const mode = searchParams.get('mode')
    const step = searchParams.get('step')

    if (mode !== 'register') {
      setSearchParams({mode: 'register', step: 'phoneNumber'})
    }

    if (mode === 'register') {
      if (step === 'phoneNumber') {
        setIsOtc(false)
      } else if (step === 'oneTimeCode') {
        setIsOtc(true)
      }
    }
  }, [searchParams, setIsOtc])

  const handlePhoneNumberSuccess = () => {
    setIsOtc(true)
    setSearchParams({mode: 'register', step: 'oneTimeCode'})
  }

  const handleOtcSuccess = () => {
    setIsOtc(false)
    setSearchParams({mode: 'apply', step: 'account'})
  }

  const handleOtcBack = () => {
    setIsOtc(false)
    setSearchParams({mode: 'register', step: 'phoneNumber'})
  }

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{mt: 2}}>
        {!isOtc ? (
          <PhoneNumber onSuccess={handlePhoneNumberSuccess} />
        ) : (
          <OneTimeCode onSuccess={handleOtcSuccess} onBack={handleOtcBack} />
        )}
      </Box>
    </Box>
  )
}
