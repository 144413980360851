import {iSocialProfile} from 'types'

// Define a type for our social media platform configurations
type SocialPlatformConfig = {
  baseUrl: string
  requiresUsername: boolean
}

// Configuration for different social media platforms
const socialPlatforms: Record<string, SocialPlatformConfig> = {
  Instagram: {baseUrl: 'https://instagram.com/', requiresUsername: true},
  X: {baseUrl: 'https://x.com/', requiresUsername: true},
  TikTok: {baseUrl: 'https://tiktok.com/@', requiresUsername: true},
  YouTube: {baseUrl: 'https://youtube.com/@', requiresUsername: true},
  Twitch: {baseUrl: 'https://twitch.tv/', requiresUsername: true},
  OnlyFans: {baseUrl: 'https://onlyfans.com/', requiresUsername: true},
  // Add more platforms as needed
}

export function cleanupSocialLinks(
  socials: iSocialProfile[],
): iSocialProfile[] {
  return socials.filter(social => {
    const platform = socialPlatforms[social.title]
    if (!platform) return false // Unknown platform, filter it out

    if (!social.href.startsWith(platform.baseUrl)) {
      // If the href doesn't start with the correct base URL, try to fix it
      if (social.href.includes(platform.baseUrl)) {
        // The base URL is somewhere in the href, extract the username
        const username = social.href.split(platform.baseUrl)[1]
        social.href = `${platform.baseUrl}${username}`
      } else if (!platform.requiresUsername) {
        // If the platform doesn't require a username, just use the base URL
        social.href = platform.baseUrl
      } else {
        // Can't fix it, filter it out
        return false
      }
    }

    if (platform.requiresUsername) {
      // Check if there's anything after the base URL
      const username = social.href.slice(platform.baseUrl.length)
      if (!username) return false // No username provided, filter it out
    }

    return true
  })
}
