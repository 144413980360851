import {
  VisibilityOffRounded,
  VisibilityRounded,
  WarningAmber,
} from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import {JoinPancake, MemberControls, PublicProfileCard} from 'components'
import {useActiveProfileBySlug} from 'hooks'
import {useOtherPublicProfiles} from 'hooks/useOtherPublicProfiles'
import {Fragment, useState} from 'react'
import {Link as RLINK, useParams} from 'react-router-dom'
import {useSigninCheck} from 'reactfire'

export function Member() {
  const {id} = useParams()
  const {
    data: profile,
    isLoading,
    refetch,
  } = useActiveProfileBySlug(id || undefined)
  const [showNsfw, setShowNsfw] = useState(false)
  const [showNsfwDialog, setShowNsfwDialog] = useState(false)
  const {data: profiles} = useOtherPublicProfiles(profile?.docId)

  const {data: member} = useSigninCheck({
    requiredClaims: {member: true},
  })

  const isOwner = member?.user?.uid === profile?.userId

  const handleNsfwToggle = () => {
    if (showNsfw) {
      setShowNsfw(false)
    } else {
      setShowNsfwDialog(true)
    }
  }

  const handleConfirmAge = (isAdult: boolean) => {
    setShowNsfwDialog(false)
    if (isAdult) {
      setShowNsfw(true)
    }
  }

  if (isLoading) return <CircularProgress />

  if (!profile)
    return (
      <Alert variant="standard" severity="error">
        Profile not found
      </Alert>
    )

  return (
    <Stack spacing={2}>
      <Stack alignItems="center" textAlign="center" spacing={1}>
        <Avatar
          style={{width: '180px', height: '180px'}}
          src={profile.pfp}
          alt={profile.displayName}
        />
        <Typography variant="h4">{profile.displayName}</Typography>
        <Typography variant="body2" color="text.secondary" maxWidth={'sm'}>
          {profile.description}
        </Typography>
      </Stack>

      {isOwner && (
        <MemberControls
          displayName={profile.displayName}
          slug={profile.slug}
          docId={profile.docId}
          refetch={refetch}
        />
      )}

      <List>
        {profile.sfw?.map((link, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={RLINK}
              to={link.href}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textAlign: 'center',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                marginBottom: '8px',
              }}
            >
              <ListItemText primary={link.title} secondary={link.href} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {profile?.nsfw && profile.nsfw.length > 0 && (
        <>
          <Divider>
            <Button
              onClick={handleNsfwToggle}
              startIcon={
                showNsfw ? <VisibilityOffRounded /> : <VisibilityRounded />
              }
            >
              {showNsfw
                ? `Hide 18+ Links (${profile.nsfw?.length})`
                : `Show 18+ Links (${profile.nsfw?.length})`}
            </Button>
          </Divider>

          {showNsfw && (
            <List>
              {profile.nsfw?.map((link, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    component={RLINK}
                    to={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textAlign: 'center',
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    <ListItemText primary={link.title} secondary={link.href} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}

      {profiles && profiles.length > 0 && (
        <Fragment>
          <Typography
            variant="overline"
            color="text.secondary"
            textAlign={'center'}
          >
            More Profiles
          </Typography>

          <Stack
            direction="row"
            spacing={3}
            flexWrap={'wrap'}
            justifyContent={'center'}
            useFlexGap
          >
            {profiles?.map(profile => (
              <PublicProfileCard key={profile.slug} profile={profile} />
            ))}
          </Stack>
        </Fragment>
      )}

      {!member?.hasRequiredClaims && <JoinPancake />}

      {/* <Divider>
        <Typography>Donate</Typography>
      </Divider>
      <ConnectKitButton /> */}

      <Dialog open={showNsfwDialog} onClose={() => setShowNsfwDialog(false)}>
        <DialogTitle>
          <Stack direction="row" spacing={1} alignItems="center">
            <WarningAmber />
            <span>Sensitive Content</span>
          </Stack>
        </DialogTitle>
        <DialogContent>
          Confirm that you are of legal age to view this content.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmAge(true)} variant="contained">
            I am 18 or older
          </Button>
          <Button onClick={() => handleConfirmAge(false)} variant="outlined">
            I am under 18
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}
