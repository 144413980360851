import {Box, Button, CircularProgress, Stack, TextField} from '@mui/material'
import {LabeledCheckbox, PancakeMascot} from 'components'
import {doc, Timestamp, updateDoc} from 'firebase/firestore'
import {useApplication} from 'hooks'
import {useEffect, useState} from 'react'
import {Controller, FormProvider, useForm} from 'react-hook-form'
import {useSearchParams} from 'react-router-dom'
import {useAuth, useFirestore} from 'reactfire'

type BankDetailsFormValues = {
  accountHolderName: string
  bankName: string
  accountNumber: string
  swiftCode: string
  sortCode: string
  iban: string
  country: string
}

export function PaymentsStep() {
  const {data: application, refetch, isLoading} = useApplication()
  const [, setSearchParams] = useSearchParams()
  const auth = useAuth()
  const firestore = useFirestore()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [enabled, setEnabled] = useState(false)

  const methods = useForm<BankDetailsFormValues>({
    mode: 'onChange',
    defaultValues: {
      accountHolderName: '',
      bankName: '',
      accountNumber: '',
      swiftCode: '',
      sortCode: '',
      iban: '',
      country: '',
    },
  })

  const {control, reset, handleSubmit} = methods

  useEffect(() => {
    if (application?.bankDetails) {
      reset(application.bankDetails)
      setEnabled(true)
    }
  }, [application, reset])

  const onSubmit = async (data: BankDetailsFormValues) => {
    if (!auth.currentUser || isSubmitting) return
    setIsSubmitting(true)

    try {
      if (application) {
        const applicationRef = doc(firestore, 'applications', application.docId)
        const formattedData = {
          bankDetails: enabled ? data : null,
          updatedAt: Timestamp.now(),
        }

        await updateDoc(applicationRef, formattedData)
        await refetch()
        console.log('Bank details updated successfully')
      }
      setSearchParams({mode: 'apply', step: 'finish'})
    } catch (error) {
      console.error('Error updating bank details:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  if (isLoading) return <CircularProgress />

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <PancakeMascot text="Where do you want to receive your payments? 💰" />

          <LabeledCheckbox
            checked={enabled}
            onChange={() => setEnabled(prev => !prev)}
            primaryLabel="Add Bank Account Details"
            secondaryLabel="Optional: Add your bank details for direct payments"
          />

          {enabled && (
            <Stack spacing={2}>
              <Controller
                name="accountHolderName"
                control={control}
                render={({field, fieldState: {error}}) => (
                  <TextField
                    {...field}
                    label="Account Holder Name"
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="bankName"
                control={control}
                render={({field, fieldState: {error}}) => (
                  <TextField
                    {...field}
                    label="Bank Name"
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="accountNumber"
                control={control}
                render={({field, fieldState: {error}}) => (
                  <TextField
                    {...field}
                    label="Account Number"
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="sortCode"
                control={control}
                render={({field, fieldState: {error}}) => (
                  <TextField
                    {...field}
                    label="Sort Code"
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="swiftCode"
                control={control}
                render={({field, fieldState: {error}}) => (
                  <TextField
                    {...field}
                    label="SWIFT Code"
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />

              <Controller
                name="iban"
                control={control}
                render={({field, fieldState: {error}}) => (
                  <TextField
                    {...field}
                    label="IBAN"
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
              <Controller
                name="country"
                control={control}
                render={({field, fieldState: {error}}) => (
                  <TextField
                    {...field}
                    label="Country"
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Stack>
          )}

          <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
            <Button
              color="inherit"
              onClick={() => setSearchParams({mode: 'apply', step: 'ids'})}
              sx={{mr: 1}}
            >
              Back
            </Button>
            <Box sx={{flex: '1 1 auto'}} />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {enabled ? 'Save and Continue' : 'Skip and Continue'}
            </Button>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}
