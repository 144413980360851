// hooks/useDocUpload.ts
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'
import {v4 as uuidv4} from 'uuid'

/**
 * Custom hook to handle document uploads to Firebase Storage.
 * @param collectionPath - The path to the collection in Firestore (e.g., "kyc").
 * @param documentId - The ID of the document to which the files are related.
 * @returns A function to upload files.
 */
export function useDocUpload(collectionPath: string, documentId: string) {
  const storage = getStorage() // Initialize Firebase Storage

  /**
   * Uploads files to Firebase Storage.
   * @param files - The files to upload.
   * @returns A promise that resolves to an array of download URLs.
   */
  const uploadFiles = async (files: FileList): Promise<string[]> => {
    try {
      // Upload files to Firebase Storage and get their URLs
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async file => {
          // Determine file extension from MIME type
          const fileExtension = file.type.split('/')[1] || 'bin' // Handle cases without an extension
          // Generate a UUID for the file name
          const fileName = `${uuidv4()}.${fileExtension}`
          const storageRef = ref(
            storage,
            `${collectionPath}/${documentId}/${fileName}`,
          )
          await uploadBytes(storageRef, file)
          const downloadURL = await getDownloadURL(storageRef) // Correctly obtain the download URL
          return downloadURL
        }),
      )

      return uploadedFiles
    } catch (error) {
      console.error('Error uploading files:', error)
      throw error // Rethrow the error for further handling
    }
  }

  return {uploadFiles}
}
