// ProfileCreation.tsx
import {useNavigate} from 'react-router-dom'
import SlugStep from './SlugStep'

export function ProfileCreation() {
  const navigate = useNavigate()

  const handleSlugCreationSuccess = (id: string, slug: string) => {
    navigate(`/profiles/${slug}/${id}/edit`)
  }

  return <SlugStep onSuccess={handleSlugCreationSuccess} />
}
