import {Alert} from '@mui/material'
import {useEffect} from 'react'
import {useUser} from 'reactfire'

export function ApplicantOnly() {
  const {data: user} = useUser()
  useEffect(() => {
    if (!user) return
    ;(async () => {
      await user.getIdToken(true)
    })()
  }, [user])
  return (
    <Alert severity="info" sx={{mb: 2}}>
      We are currently reviewing your application.
    </Alert>
  )
}
