import {Box, Link, Typography} from '@mui/material'
import {ResponsiveStepper} from 'forms/apply/Stepper'
import {useProfile} from 'hooks'
import {useEffect} from 'react'
import {
  Link as RLink,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {ROUTES} from 'router'
import {DetailsStep} from './DetailsStep'
import {NSFWLinks} from './NSFWLinks'
import {PfpStep} from './PfpStep'
import {PreviewStep} from './Preview'
import {SFWLinks} from './SFWLinks'

const steps = ['details', 'pfp', 'sfw-links', 'nsfw-links', 'preview']

export function ProfileWizard() {
  const {slugId, docId} = useParams<{slugId: string; docId: string}>()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!slugId || !docId) {
      navigate(ROUTES.PROFILE_CREATE)
    }
  }, [slugId, docId, navigate])

  useProfile(docId ?? null)

  useEffect(() => {
    const mode = searchParams.get('mode')
    const step = searchParams.get('step')

    if (mode !== 'edit' && !step && !steps.includes(step ?? '')) {
      setSearchParams({mode: 'edit', step: 'details'})
    }
  }, [searchParams, setSearchParams])

  const renderStepContent = (step: string) => {
    switch (step) {
      case 'details':
        return (
          <DetailsStep
            profileId={docId!}
            onSuccess={() => handleStepClick(1)}
            onBack={() => handleStepClick(0)}
          />
        )
      case 'pfp':
        return (
          <PfpStep
            onSuccess={() => handleStepClick(2)}
            onBack={() => handleStepClick(0)}
          />
        )
      case 'sfw-links':
        return (
          <SFWLinks
            onSuccess={() => handleStepClick(3)}
            onBack={() => handleStepClick(1)}
          />
        )
      case 'nsfw-links':
        return (
          <NSFWLinks
            onSuccess={() => handleStepClick(4)}
            onBack={() => handleStepClick(2)}
          />
        )
      case 'preview':
        return <PreviewStep onBack={() => handleStepClick(2)} />
      default:
        return <Typography>Invalid step</Typography>
    }
  }

  const currentStep = searchParams.get('step') || 'details'
  const activeStep = steps.indexOf(currentStep)

  const handleStepClick = (index: number) => {
    setSearchParams({mode: 'edit', step: steps[index]})
  }

  return (
    <Box sx={{width: '100%'}}>
      <Typography variant="h6" gutterBottom>
        Edit profile:{' '}
        <Link component={RLink} to={'/m/' + slugId}>
          /m/{slugId}
        </Link>
      </Typography>

      <ResponsiveStepper
        steps={steps}
        activeStep={activeStep}
        handleStepClick={handleStepClick}
      />

      <Box sx={{mt: 2}}>{renderStepContent(currentStep)}</Box>
    </Box>
  )
}
