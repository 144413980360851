import {Email, Instagram, Telegram, X} from '@mui/icons-material'
import {IconButton, Stack, Tooltip} from '@mui/material'

export function SocialLinks() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      justifyContent={'center'}
    >
      <Tooltip title="x.com/pancake_bio">
        <IconButton href="https://x.com/pancake_bio" target="_blank">
          <X fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="t.me/pancake_bio">
        <IconButton href="https://t.me/pancake_bio" target="_blank">
          <Telegram fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="instagram.com/pancake_bio">
        <IconButton href="https://instagram.com/pancake_bio" target="_blank">
          <Instagram fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="hello@pancake.bio">
        <IconButton href="mailto:hello@pancake.bio" target="_blank">
          <Email fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}
