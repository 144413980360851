import {
  VisibilityOffRounded,
  VisibilityRounded,
  WarningAmber,
} from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import {Timestamp} from 'firebase/firestore'
import {useProfile, useUpdateProfileDetails} from 'hooks'
import React, {useState} from 'react'
import {Link as RLINK, useNavigate, useParams} from 'react-router-dom'
import {ROUTES} from 'router'

interface PreviewStepProps {
  onBack: () => void
}

export function PreviewStep({onBack}: PreviewStepProps) {
  const [showNsfw, setShowNsfw] = useState(false)
  const [showNsfwDialog, setShowNsfwDialog] = useState(false)
  const {docId} = useParams<{docId: string}>()
  const {data: profile, isLoading} = useProfile(docId || null)
  const [isActive, setIsActive] = useState(profile?.active ?? true)
  const [isTos, setIsTos] = useState(profile?.terms ?? false)
  const navigate = useNavigate()
  const updateProfile = useUpdateProfileDetails(docId ?? '')

  const handleNsfwToggle = () => {
    if (showNsfw) {
      setShowNsfw(false)
    } else {
      setShowNsfwDialog(true)
    }
  }

  const handleConfirmAge = (isAdult: boolean) => {
    setShowNsfwDialog(false)
    if (isAdult) {
      setShowNsfw(true)
    }
  }

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked)
  }

  const handleTosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTos(event.target.checked)
  }

  const handleComplete = async () => {
    if (!isActive) return navigate(ROUTES.PROFILES)

    // Save profile as active
    updateProfile.mutate(
      {
        active: true,
        publishedAt: Timestamp.now(),
        terms: isTos,
        termsAt: Timestamp.now(),
      },
      {
        onSuccess: () => {
          navigate('/m/' + profile?.slug)
        },
      },
    )
  }

  if (isLoading) return <CircularProgress />

  if (!profile)
    return (
      <Alert variant="standard" severity="error">
        Profile not found
      </Alert>
    )

  return (
    <Stack spacing={2}>
      <Stack alignItems="center" textAlign="center" spacing={1}>
        <Avatar
          style={{width: '180px', height: '180px'}}
          src={profile.pfp}
          alt={profile.displayName}
        />
        <Typography variant="h4">{profile.displayName}</Typography>
        <Typography variant="body2" color="text.secondary" maxWidth={'sm'}>
          {profile.description}
        </Typography>
      </Stack>

      <List>
        {profile.sfw?.map((link, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={RLINK}
              to={link.href}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textAlign: 'center',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
                marginBottom: '8px',
              }}
            >
              <ListItemText primary={link.title} secondary={link.href} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {profile?.nsfw && profile.nsfw.length > 0 && (
        <>
          <Divider>
            <Button
              onClick={handleNsfwToggle}
              startIcon={
                showNsfw ? <VisibilityOffRounded /> : <VisibilityRounded />
              }
            >
              {showNsfw ? 'Hide 18+ Content' : 'Show 18+ Content'}
            </Button>
          </Divider>

          {showNsfw && (
            <List>
              {profile.nsfw?.map((link, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    component={RLINK}
                    to={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textAlign: 'center',
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    <ListItemText primary={link.title} secondary={link.href} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={isActive}
            onChange={handleActiveChange}
            color="primary"
          />
        }
        label="Set profile as active"
      />

      {!isActive && (
        <Alert severity="warning">Your profile will be set as inactive.</Alert>
      )}

      {isActive && (
        <Alert severity="info">
          Your profile will be set active, and available to the internet.
        </Alert>
      )}

      {isActive && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isTos}
              onChange={handleTosChange}
              color="primary"
            />
          }
          label={
            <Typography>
              I agree to Pancake Bio's{' '}
              <Link
                component={RLINK}
                to={ROUTES.TERMS}
                target="_blank"
                underline="hover"
              >
                Terms of Service
              </Link>
            </Typography>
          }
        />
      )}

      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Button onClick={onBack} color="inherit">
          Back
        </Button>
        <Button
          onClick={handleComplete}
          variant="contained"
          color="primary"
          disabled={isActive && !isTos}
        >
          {isActive ? 'Publish' : 'Save as Draft'}
        </Button>
      </Stack>

      <Dialog open={showNsfwDialog} onClose={() => setShowNsfwDialog(false)}>
        <DialogTitle>
          <Stack direction="row" spacing={1} alignItems="center">
            <WarningAmber />
            <span>Sensitive Content</span>
          </Stack>
        </DialogTitle>
        <DialogContent>
          Confirm that you are of legal age to view this content.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmAge(true)} variant="contained">
            I am 18 or older
          </Button>
          <Button onClick={() => handleConfirmAge(false)} variant="outlined">
            I am under 18
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}
