import {useQuery} from '@tanstack/react-query'
import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
  limit,
  query,
  QuerySnapshot,
  where,
} from 'firebase/firestore'
import {ProfileData} from 'types'

const fetchOtherPublicProfiles = async (
  firestore: any,
  currentProfileId: string | undefined,
  limitCount: number = 3,
): Promise<ProfileData[]> => {
  const profilesRef = collection(firestore, 'profiles')
  let publicProfilesQuery = query(
    profilesRef,
    where('active', '==', true),
    limit(limitCount + 1), // Fetch one extra to account for possible current profile
  )

  const publicProfilesSnapshot: QuerySnapshot<DocumentData> =
    await getDocs(publicProfilesQuery)

  const profiles = publicProfilesSnapshot.docs
    .map(doc => ({
      ...(doc.data() as Omit<ProfileData, 'docId'>),
      docId: doc.id,
    }))
    .filter(profile => profile.docId !== currentProfileId)
    .slice(0, limitCount)

  return profiles
}

export function useOtherPublicProfiles(
  currentProfileId: string | undefined,
  limitCount: number = 3,
) {
  const firestore = getFirestore()

  return useQuery<ProfileData[]>({
    queryKey: ['otherPublicProfiles', currentProfileId, limitCount],
    queryFn: () =>
      fetchOtherPublicProfiles(firestore, currentProfileId, limitCount),
    enabled: !!currentProfileId,
  })
}
