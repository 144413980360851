import {
  AaliyahYasin,
  Mock10,
  Mock12,
  Mock2,
  Mock4,
  Mock5,
  Mock6,
  Mock7,
  Mock8,
  Mock9,
} from 'assets'

export const members = [
  {
    name: 'Aaliyah Yasin',
    profileLink: '/m/aaliyah-yasin',
    imagePath: AaliyahYasin,
  },
  {
    name: 'Mr Pancake',
    profileLink: '/m/mr-pancake',
    imagePath: Mock9,
  },
  {
    name: 'Sara Smith',
    profileLink: '/m/sara-smith',
    imagePath: Mock2,
  },
  {
    name: 'John Doe',
    profileLink: '/m/john-doe',
    imagePath: Mock12,
  },
  {
    name: 'Emma Johnson',
    profileLink: '/m/emma-johnson',
    imagePath: Mock4,
  },
  {
    name: 'Chris Martin',
    profileLink: '/m/chris-martin',
    imagePath: Mock5,
  },
  {
    name: 'Nina Brown',
    profileLink: '/m/nina-brown',
    imagePath: Mock6,
  },
  {
    name: 'James Clark',
    profileLink: '/m/james-clark',
    imagePath: Mock7,
  },
  {
    name: 'Sophia Wilson',
    profileLink: '/m/sophia-wilson',
    imagePath: Mock8,
  },
  {
    name: 'Michael Scott',
    profileLink: '/m/michael-scott',
    imagePath: Mock9,
  },
  {
    name: 'Olivia Davis',
    profileLink: '/m/olivia-davis',
    imagePath: Mock10,
  },
]
