import {getDefaultConfig} from 'connectkit'
import {createConfig, http} from 'wagmi'
import {
  arbitrum,
  arbitrumGoerli,
  avalanche,
  avalancheFuji,
  bsc,
  bscTestnet,
  celo,
  dogechain,
  fantom,
  fantomTestnet,
  goerli,
  linea,
  mainnet,
  mantle,
  opBNB,
  optimism,
  optimismGoerli,
  palm,
  polygon,
  polygonMumbai,
  sepolia,
  zksync,
} from 'wagmi/chains'
import {coinbaseWallet, injected, walletConnect} from 'wagmi/connectors'

export const config = createConfig({
  chains: [mainnet, sepolia],
  connectors: [
    injected(),
    coinbaseWallet(),
    walletConnect({projectId: import.meta.env.VITE_WC_PROJECT_ID!}),
  ],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
})

declare module 'wagmi' {
  interface Register {
    config: typeof config
  }
}

export const config2 = createConfig(
  getDefaultConfig({
    chains:
      import.meta.env.MODE === 'production'
        ? [
            mainnet, // Ethereum Mainnet
            bsc, // Binance Smart Chain Mainnet
            polygon, // Polygon Mainnet
            avalanche, // Avalanche Mainnet
            arbitrum, // Arbitrum Mainnet
            fantom, // Fantom Mainnet
            optimism, // Optimism Mainnet
            dogechain, // Dogechain Mainnet
          ]
        : [
            mainnet, // Ethereum Mainnet
            bsc, // Binance Smart Chain Mainnet
            polygon, // Polygon Mainnet
            avalanche, // Avalanche Mainnet
            arbitrum, // Arbitrum Mainnet
            fantom, // Fantom Mainnet
            optimism, // Optimism Mainnet
            dogechain, // Dogechain Mainnet
            fantomTestnet, // Fantom Testnet
            sepolia, // Ethereum Sepolia Testnet
            goerli, // Ethereum Goerli Testnet
            optimismGoerli, // Optimism Goerli Testnet
            bscTestnet, // Binance Smart Chain Testnet
            arbitrumGoerli, // Arbitrum Goerli Testnet
            avalancheFuji, // Avalanche Fuji Testnet
            polygonMumbai, // Polygon Mumbai Testnet
          ],
    transports:
      import.meta.env.MODE === 'production'
        ? {
            // Production Transports using Alchemy
            [mainnet.id]: http(
              `https://eth-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_KEY}`,
            ), // Alchemy Ethereum Mainnet
            [sepolia.id]: http(
              `https://sepolia.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Ethereum Sepolia Testnet
            [polygon.id]: http(
              `https://polygon-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_KEY}`,
            ), // Alchemy Polygon Mainnet
            [avalanche.id]: http(
              `https://avalanche-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Avalanche Mainnet
            [arbitrum.id]: http(
              `https://arb-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_KEY}`,
            ), // Alchemy Arbitrum Mainnet
            [optimism.id]: http(
              `https://optimism-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Optimism Mainnet
            [fantom.id]: http(
              `https://fantom-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_KEY}`,
            ), // Alchemy Fantom Mainnet
            [bsc.id]: http(
              `https://bsc-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Binance Smart Chain Mainnet
            [mantle.id]: http(
              `https://mantle-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_KEY}`,
            ), // Alchemy Mantle Mainnet
            [opBNB.id]: http(
              `https://opbnb-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura opBNB Mainnet
            [zksync.id]: http(
              `https://zksync-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_KEY}`,
            ), // Alchemy ZKsync Mainnet
            [celo.id]: http(
              `https://celo-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Celo Mainnet
            [linea.id]: http(
              `https://linea-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Linea Mainnet
            [palm.id]: http(
              `https://palm-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Palm Mainnet
          }
        : {
            // Development Transports using Infura
            [mainnet.id]: http(
              `https://mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Ethereum Mainnet
            [sepolia.id]: http(
              `https://sepolia.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Ethereum Sepolia Testnet
            [polygon.id]: http(
              `https://polygon-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Polygon Mainnet
            [avalanche.id]: http(
              `https://avalanche-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Avalanche Mainnet
            [arbitrum.id]: http(
              `https://arbitrum-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Arbitrum Mainnet
            [optimism.id]: http(
              `https://optimism-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Optimism Mainnet
            [fantom.id]: http(
              `https://fantom-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_KEY}`,
            ), // Alchemy Fantom Mainnet
            [bsc.id]: http(
              `https://bsc-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Binance Smart Chain Mainnet
            [mantle.id]: http(
              `https://mantle-mainnet.g.alchemy.com/v2/${import.meta.env.VITE_ALCHEMY_KEY}`,
            ), // Alchemy Mantle Mainnet
            [opBNB.id]: http(
              `https://opbnb-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura opBNB Mainnet
            [zksync.id]: http(
              `https://zksync-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura ZKsync Mainnet
            [celo.id]: http(
              `https://celo-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Celo Mainnet
            [linea.id]: http(
              `https://linea-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Linea Mainnet
            [palm.id]: http(
              `https://palm-mainnet.infura.io/v3/${import.meta.env.VITE_INFURA_KEY}`,
            ), // Infura Palm Mainnet
          },

    connectors: [
      injected(),
      coinbaseWallet(),
      walletConnect({
        projectId: import.meta.env.VITE_WC_PROJECT_ID,
        metadata: {
          name: 'Pancake Bio',
          url: 'https://pancake.bio',
          description: 'Pancake Bio is a decentralized identity platform.',
          icons: ['https://pancake.bio/pancake_bio_logo.png'],
        },
      }),
    ],
    walletConnectProjectId: import.meta.env.VITE_WC_PROJECT_ID,
    appName: 'Pancake Bio',
    appDescription: 'Pancake Bio is a decentralized identity platform.',
    appUrl: 'https://pancake.bio',
    appIcon: 'https://pancake.bio/pancake_bio_logo.png',
  }),
)
