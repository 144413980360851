import {yupResolver} from '@hookform/resolvers/yup'
import {ArrowForward} from '@mui/icons-material'
import {
  Alert,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from 'firebase/auth'
import {useEffect, useState} from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import * as yup from 'yup'

interface ISignInWithEmailProps {
  email: string
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email(`Really, who are you trying to fool? This is not a valid email.`)
    .required(`Aww, c’mon! I need your email address.`),
})

const actionCodeSettings = {
  url: import.meta.env.PROD
    ? 'https://pancake.bio/join?mode=signIn'
    : 'http://127.0.0.1:5173/join?mode=signIn', // Replace with your actual URL
  handleCodeInApp: true,
}

export function SignInWithEmail() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<ISignInWithEmailProps>({
    resolver: yupResolver(schema),
    mode: 'all',
  })

  const auth = getAuth()
  const [emailRequired, setEmailRequired] = useState(false)
  const [notification, setNotification] = useState<string | null>(null)

  // check to see if email is required;
  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        setEmailRequired(true)
      } else {
        completeSignIn(email)
      }
    }
  }, [auth])

  // complete sign in
  const completeSignIn = async (email: string) => {
    try {
      await signInWithEmailLink(auth, email, window.location.href)
      window.localStorage.removeItem('emailForSignIn')
      setNotification(`Captain, We're into the system!`)
    } catch (error) {
      console.error(`Oh, Noes, Some Error?!`, error)
      setNotification((error as Error).message)
    }
  }

  const onSubmit: SubmitHandler<ISignInWithEmailProps> = async data => {
    if (emailRequired) {
      completeSignIn(data.email)
    } else {
      try {
        await sendSignInLinkToEmail(auth, data.email, actionCodeSettings)
        window.localStorage.setItem('emailForSignIn', data.email)
        setNotification(`Sent Nudes, check your inbox!`)
      } catch (error) {
        console.error(`Oh, Noes, Some Error?!`, error)
        setNotification((error as Error).message)
      }
    }
  }

  return (
    <Stack spacing={1} width="100%" textAlign={'center'}>
      <Typography variant="overline" color="textSecondary">
        {emailRequired
          ? 'Confirm your Email to get started'
          : 'Enter your Email to get started'}
      </Typography>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        spacing={2}
        textAlign={'center'}
      >
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          autoComplete="email"
          autoFocus
          {...register('email')}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ''}
          slotProps={{
            input: {
              endAdornment: (
                <IconButton type="submit" disabled={!!errors.email}>
                  <ArrowForward />
                </IconButton>
              ),
            },
          }}
        />
        {notification && (
          <Box>
            <Alert
              severity={emailRequired ? 'info' : 'success'}
              variant="outlined"
            >
              {notification}
            </Alert>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}
